import upperFirst from 'lodash/upperFirst'
import {
  IS_NETZKINO_LIKE,
  SMPL_CUSTOMER,
  isArdCustomer,
  isFilmhomeCustomer,
  isFlexgoldCustomer,
  isFlorianhommCustomer,
  isKixiCustomer,
  isNetzkinoCustomer,
  isUniversumCustomer,
} from '../../lib/config'
import { CategoryFlagsData, MovieFlagsData } from './FlagSetting'
import {
  channelsOption_ARD,
  channelsOption_NK,
  countryOptions,
  currencyCodes,
  foreignPortalsOption_NK,
  portalsOption_NK,
  propertiesOptions,
} from './OptionSetting'

/**
 * min width of 150px
 */
export const sizeXS = 'sizeXS'
/**
 * min width of 200px
 */
export const sizeS = 'sizeS'
/**
 * min width of 250px
 */
export const sizeM = 'sizeM'
/**
 * min width of 300px
 */
export const sizeL = 'sizeL'
/**
 * min width of 450px
 */
export const sizeXL = 'sizeXL'
/**
 * width of 100%
 */
export const sizeMax = 'sizeMax'

/**
 * add a right margin of 10px
 */
export const marginRight = 'marginRight'

/**
 * give the input field a min height of 70px (may not work with every field)
 */
export const heigherField = 'heigherField'

/**
 * give the input field a min height of 70px (may not work with every field)
 */
export const noBGColor = 'noBGColor'

/**
 * add a top margin of 8px to the field
 */
export const marginTop = 'marginTop'

// Group 1 - left (name will not be shown)
export const HEADLINE = 'Title'

// Group
export const IDENTIFICATION = 'Identification'
export const SYNOPSIS = 'Synopsis'
export const STATUS = 'Status'
export const PRODUCT = 'Product'
export const SMART_CATEGORY = 'Filter Based Content Generation'
export const AWS = 'Amazon Web Services'
export const ESSENTIAL = 'Essential Fields'
export const LICENCE = 'Licence Information'

// Group 2 - right (will be shown as group in accordion)
export const SEO = 'Search engine optimization'
export const IMAGE = 'Image'
export const LANGUAGES = 'Language'
export const VIDEOINFO = 'Video information'
export const RELATED = 'Connection'
export const OTHERS = 'Additional Metadata'
export const ADVANCED = 'Advanced Setting'
export const CUSTOM_DATA = 'Custom Data'
export const COMMENT = 'Comment'
export const USER = 'User'
export const WIDGET = 'Widget Links'

// will not be displaying
export const NONE = 'Not Displaying'

// Group Order, if not listed then render in OTHERS
// affect all declared(!) Types in TypeDisplaySetting
export const GroupOrder = {
  primary: [
    HEADLINE,
    IDENTIFICATION,
    STATUS,
    SYNOPSIS,
    ESSENTIAL,
    AWS, // only there in transcodingJob
    PRODUCT,
    SMART_CATEGORY,
    LICENCE,
  ],
  secondary: [
    IMAGE,
    SEO,
    VIDEOINFO,
    RELATED,
    LANGUAGES,
    OTHERS,
    ADVANCED,
    CUSTOM_DATA,
    COMMENT,
    USER,
    WIDGET,
  ],
}

const singleLineTextField = {
  type: 'TEXT_INPUT',
  props: {},
}

const ignoreMaxLengthTextField = {
  type: 'TEXT_INPUT',
  props: {},
}

const isActiveProps = {
  valueLabelTrue:
    'Content is currently publically available.\nNotice that this state is only recalculated every 10 minutes!',
  valueLabelFalse:
    'Content is currently NOT publically available.\nNotice that this state is only recalculated every 10 minutes!',
} as const

const listTypeName = {
  // FieldDefinition:
  masterUrl: {
    type: 'URL_FIELD_POPUP',
    props: {
      TransitionProps: {
        timeout: 500,
      },
      enterDelay: 500,
      placement: 'top',
    },
  },
  productionYear: singleLineTextField,
  id: singleLineTextField,
  isActive: {
    type: 'ISACTIVE',
    props: isActiveProps,
  },
}

const productGroupDropDown = {
  type: 'DROPDOWN_INPUT',
  props: {
    label: 'Product Group',
    inputProps: {
      options: [
        { label: 'No Group (empty value)', value: null },
        { label: 'Yearly', value: 'yearly' },
        { label: '3 Months', value: '3monthly' },
        { label: '1 Month', value: 'monthly' },
      ],
    },
    custom_style: [sizeMax, marginTop],
  },
}

const productContentAccessDropDown = {
  type: 'DROPDOWN_INPUT',
  props: {
    label: 'Content Access',
    inputProps: {
      options: [
        { label: 'AVOD (empty value)', value: null },
        { label: 'ADFREE', value: 'adfree' },
        { label: 'SVOD', value: 'svod' },
        { label: 'EST', value: 'est' },
      ],
    },
    custom_style: [sizeMax, marginTop],
  },
}

const productCurrencyDropdown = {
  type: 'DROPDOWN_INPUT',
  props: {
    label: 'Currency Code',
    inputProps: {
      options: [...currencyCodes],
    },
    custom_style: [sizeMax, marginTop],
  },
}

// Ordering also matters
const createShowEditFieldGrouping = {
  // INFO Group 1
  // IDENTIFICATION
  title: { group: IDENTIFICATION },
  originalTitle: { group: IDENTIFICATION },
  name: { group: IDENTIFICATION },
  displayName: { group: IDENTIFICATION },
  transactionStep: { group: IDENTIFICATION },
  contactPerson: { group: IDENTIFICATION },
  slug: { group: IDENTIFICATION },
  imdbIdentifier: { group: IDENTIFICATION },
  isan: { group: IDENTIFICATION },
  contractType: { group: IDENTIFICATION },
  parentId: { group: IDENTIFICATION },
  description: { group: IDENTIFICATION },
  externalIdentifier: { group: IDENTIFICATION },
  vendorId: { group: IDENTIFICATION },
  contentExternalIdentifier: { group: IDENTIFICATION },
  contractDate: { group: IDENTIFICATION },
  username: { group: IDENTIFICATION },
  email: { group: IDENTIFICATION },
  role: { group: IDENTIFICATION },

  // SYNOPSIS
  longSynopsis: { group: SYNOPSIS },
  mediumSynopsis: { group: SYNOPSIS },
  shortSynopsis: { group: SYNOPSIS },
  supershortSynopsis: { group: SYNOPSIS },

  // ESSENTIAL
  channel: { group: ESSENTIAL },
  activeCountries: { group: ESSENTIAL },
  licenseStart: { group: ESSENTIAL },
  licenseEnd: { group: ESSENTIAL },
  contractStart: { group: ESSENTIAL },
  contractEnd: { group: ESSENTIAL },

  // STATUS
  hasRecurringActiveSubscription: { group: STATUS },
  hasActiveSubscription: { group: STATUS },
  isTrialEligible: { group: STATUS },
  emailVerified: { group: STATUS },
  marketingMailsAllowed: { group: STATUS },
  available: { group: STATUS },
  progress: { group: STATUS },
  isVisible: { group: STATUS },
  isActive: { group: STATUS },
  isRecurringActive: { group: STATUS },
  missingImageAsset: { group: STATUS },
  hasEnoughConnections: { group: STATUS },
  isActiveByActiveTimers: { group: STATUS },
  minActiveAmount: { group: STATUS },
  activeTimerStart: { group: STATUS },
  activeTimerEnd: { group: STATUS },

  // LICENCE - displaying earliest active license on movie/series and feedObject
  validLicenseStart: { group: LICENCE },
  validLicenseEnd: { group: LICENCE },
  amountOnlineLicenses: { group: LICENCE },
  activeLicenseOnPlatform: { group: LICENCE },

  // INFO Group 2
  // IMAGE
  masterUrl: { group: IMAGE },
  coverImageId: { group: IMAGE }, // for all
  coverImageBrandedId: { group: isArdCustomer ? IMAGE : NONE },
  coverImageBranded23Channel1Id: { group: isArdCustomer ? IMAGE : NONE },
  widescreenImageId: { group: IMAGE }, // for all
  widescreenImageTextlessId: { group: isArdCustomer ? IMAGE : NONE },
  widescreenImageBrandedId: {
    group: isArdCustomer || isFilmhomeCustomer ? IMAGE : NONE,
  },
  widescreenImageBranded169Channel1Id: { group: isArdCustomer ? IMAGE : NONE },
  artLogoImageId: { group: isArdCustomer || isNetzkinoCustomer ? IMAGE : NONE },
  heroLandscapeImageId: {
    group: isArdCustomer || isNetzkinoCustomer ? IMAGE : NONE,
  },
  heroPortraitImageId: {
    group: isArdCustomer || isNetzkinoCustomer ? IMAGE : NONE,
  },
  primaryImageId: { group: isArdCustomer || isNetzkinoCustomer ? IMAGE : NONE },
  videoStillImageId: {
    group: isArdCustomer || isNetzkinoCustomer ? IMAGE : NONE,
  },
  headerImageId: { group: IMAGE }, // for all
  headerImage24By9Id: { group: isArdCustomer ? IMAGE : NONE },
  logoId: { group: IMAGE }, // for all
  customImage920X368Id: { group: isNetzkinoCustomer ? IMAGE : NONE },
  customImage1440X540Id: { group: isNetzkinoCustomer ? IMAGE : NONE },
  image: { group: IMAGE },

  // WIDGET
  linkedMovieId: {
    group: isArdCustomer || isFlorianhommCustomer ? WIDGET : NONE,
  },
  linkedSeriesId: {
    group: isArdCustomer || isFlorianhommCustomer ? WIDGET : NONE,
  },
  linkedSeasonId: {
    group: isArdCustomer || isFlorianhommCustomer ? WIDGET : NONE,
  },
  linkedEpisodeId: {
    group: isArdCustomer || isFlorianhommCustomer ? WIDGET : NONE,
  },
  linkedCategoryId: {
    group: isArdCustomer || isFlorianhommCustomer ? WIDGET : NONE,
  },
  linkedUrl: { group: isArdCustomer || isFlorianhommCustomer ? WIDGET : NONE },

  // SEO
  seoTitle: { group: SEO },
  seoDescription: { group: SEO },
  seoTags: {
    // ObjectArrayJsonFields
    group: SEO,
  },
  seoSharingImageId: {
    group: SEO,
  },

  // LANGUAGES

  // VIDEOINFO
  runtimeInSeconds: { group: VIDEOINFO },
  introStartTime: { group: VIDEOINFO },
  introEndTime: { group: VIDEOINFO },
  creditsStartTime: { group: VIDEOINFO },
  adBreakStartTimes: { group: VIDEOINFO },
  hasVideoSource: { group: VIDEOINFO },
  hasDrm: { group: VIDEOINFO },
  drmEncrypted: { group: VIDEOINFO },
  dashAudioInfo: { group: VIDEOINFO },
  dashTextTrackInfo: { group: VIDEOINFO },
  hlsAudioInfo: { group: VIDEOINFO },
  hlsTextTrackInfo: { group: VIDEOINFO },

  // RELATED
  userId: { group: RELATED },
  subscriptionId: { group: RELATED },
  productId: { group: RELATED },
  paymentTransactionId: { group: RELATED },
  subscriptionTermId: { group: RELATED },
  subscriptionProductId: { group: RELATED },
  parentPaymentTransactionId: { group: RELATED },
  usedVoucherId: { group: RELATED },
  movieId: { group: RELATED },
  episodeId: { group: RELATED },
  clipId: { group: RELATED },
  licenseId: { group: RELATED },
  parentSeriesId: { group: RELATED },
  parentMovieId: { group: RELATED },
  seriesId: { group: RELATED },
  contractId: { group: RELATED },
  contentMovieId: { group: RELATED },
  contentEpisodeId: { group: RELATED },
  contentSeriesId: { group: RELATED },
  contentFeedObjectId: { group: RELATED },
  wirecardTransactionId: {
    group: isNetzkinoCustomer || isUniversumCustomer ? RELATED : NONE,
  },
  paypalTransactionId: { group: RELATED },
  appleTransactionId: { group: RELATED },
  stripeTransactionId: { group: RELATED },

  // AWS
  awsStatus: { group: AWS },
  awsLastNotification: { group: AWS },
  awsResponseCode: { group: AWS },
  awsResponseDescription: { group: AWS },

  // USER
  userName: { group: USER },
  userTaxNumber: { group: USER },
  userAddressState: { group: USER },
  userAddressPublicPlaceCategory: { group: USER },
  userAddressType: { group: USER },
  userAddressCity: { group: USER },
  userAddressCountryCode: { group: USER },
  userAddressPostalCode: { group: USER },
  userAddressStreet: { group: USER },
  userAddressStreetNumber: { group: USER },

  // PRODUCT
  productName: { group: PRODUCT },
  productPrice: { group: PRODUCT },
  productPaidPrice: { group: PRODUCT },
  productCurrencyCode: { group: PRODUCT },
  productType: { group: PRODUCT },
  productContentAccess: { group: PRODUCT },
  price: { group: PRODUCT },
  currencyCode: { group: PRODUCT },
  type: { group: PRODUCT },

  // OTHERS
  exclusive: { group: OTHERS },
  uncut: { group: OTHERS },
  omu: { group: OTHERS },
  ov: { group: OTHERS },
  originalLanguage: { group: OTHERS },
  copyright: { group: OTHERS },
  fskRating: { group: OTHERS },
  offlineAvailableDays: { group: OTHERS },
  productionYear: { group: OTHERS },
  cinemaStart: { group: OTHERS },
  rating: { group: OTHERS },
  productGroup: { group: OTHERS },
  accessToContentFlag: { group: OTHERS },
  productionCountry: { group: OTHERS },
  platform: { group: OTHERS },
  originCountry: { group: OTHERS },
  properties: { group: OTHERS },
  channels: { group: OTHERS },
  portals: { group: OTHERS },
  foreignPortals: { group: OTHERS },
  languages: { group: OTHERS },
  subtitleLanguages: { group: OTHERS },
  customData: { group: OTHERS },
  paymentProviderId: {
    group: OTHERS,
    type: 'URL_FIELD',
    props: {
      label: 'Payment Provider Id',
      inputProps: {
        useFieldValueAsUrl: false,
      },
    },
  },
  liveStreamUrl: { group: isNetzkinoCustomer ? OTHERS : NONE },

  // ADVANCED
  videoSource: { group: ADVANCED },
  searchIndex: {
    group: ADVANCED,
    type: 'CHIP_FIELD',
    props: {
      label: 'Search Index',
      inputProps: {
        separator: ' ',
      },
    },
  },

  // COMMENT
  comment: { group: COMMENT },
  systemNotifications: { group: COMMENT },

  // INFO Not displaying
  // NONE
  audioLanguages: { group: NONE },
  encodingHouseId: { group: NONE },
  status: { group: isFlexgoldCustomer ? OTHERS : NONE },
  licensorId: { group: NONE },
  publishingRegions: { group: NONE },
  externalVideoSource: {
    group: NONE,
  },
}

// INFO: the order here define the order of input in create/edit page inside their group, not the order of the group
const createEditTypeName = {
  ...createShowEditFieldGrouping,

  // INFO Group 1
  // HEADLINE, only 1 should exist, should be exclusive
  title: {
    group: HEADLINE,
    props: {
      custom_style: [heigherField],
    },
  },
  // IDENTIFICATION
  originalTitle: {
    group: IDENTIFICATION,
    type: 'TEXT_INPUT',
    props: {
      custom_style: [marginRight, sizeS],
    },
  },
  slug: {
    group: IDENTIFICATION,
    type: 'TEXT_INPUT',
    props: {
      custom_style: [marginRight, sizeS],
    },
  },
  imdbIdentifier: {
    group: IDENTIFICATION,
    type: 'IMDB_IMPORT_INPUT',
    props: {
      label: 'Imdb-Id',
      custom_style: [marginRight, sizeXS],
    },
  },
  isan: {
    group: IDENTIFICATION,
    type: 'TEXT_INPUT',
    props: {
      custom_style: [marginRight, sizeXS],
    },
  },
  contractType: {
    group: IDENTIFICATION,
    type: 'DROPDOWN_INPUT',
    props: {
      label: 'Contract Type',
      inputProps: {
        options: [
          { label: 'No Type', value: null },
          ...(isNetzkinoCustomer
            ? [
                {
                  label: 'HV',
                  value: 'HV',
                },
                {
                  label: 'EV',
                  value: 'EV',
                },
                {
                  label: 'EVS',
                  value: 'EVS',
                },
              ]
            : []),
        ].filter(Boolean),
      },
      custom_style: [sizeMax, marginTop],
    },
  },
  externalIdentifier: {
    group: IDENTIFICATION,
    type: 'TEXT_INPUT',
    props: {
      custom_style: [sizeXL],
    },
  },
  skuAvod: {
    type: 'TEXT_INPUT',
    group: isNetzkinoCustomer ? IDENTIFICATION : NONE,
    props: {
      label: 'Koch Artikel-Nr. AVOD',
    },
  },
  skuSvod: {
    type: 'TEXT_INPUT',
    group: isNetzkinoCustomer ? IDENTIFICATION : NONE,
    props: {
      label: 'Koch Artikel-Nr. SVOD',
    },
  },
  skuFast: {
    type: 'TEXT_INPUT',
    group: isNetzkinoCustomer ? IDENTIFICATION : NONE,
    props: {
      label: 'Koch Artikel-Nr. FAST',
    },
  },

  // SYNOPSIS
  longSynopsis: {
    type: 'LONG_TEXT_INPUT',
    props: {
      label: 'Long Synopsis',
      inputProps: {
        maxLength: 2000,
      },
      custom_style: [heigherField, marginRight, sizeMax],
      helperText: 'Maximum of 2000 characters.',
    },
    group: SYNOPSIS,
  },
  mediumSynopsis: {
    type: 'LONG_TEXT_INPUT',
    props: {
      label: 'Medium Synopsis',
      inputProps: {
        maxLength: isArdCustomer ? 800 : 400,
      },
      custom_style: [heigherField, marginRight, sizeMax],
      helperText: `Maximum of ${isArdCustomer ? '800' : '400'} characters.`,
    },
    group: SYNOPSIS,
  },
  shortSynopsis: {
    type: 'LONG_TEXT_INPUT',
    props: {
      label: 'Short Synopsis',
      inputProps: {
        maxLength: isArdCustomer ? 400 : 190,
      },
      custom_style: [heigherField, marginRight, sizeXL],
      helperText: `Maximum of ${isArdCustomer ? '400' : '190'} characters.`,
    },
    group: SYNOPSIS,
  },
  supershortSynopsis: {
    type: 'LONG_TEXT_INPUT',
    props: {
      label: 'Super Short Synopsis',
      inputProps: {
        maxLength: 80,
      },
      custom_style: [heigherField, marginRight, sizeL],
      helperText: 'Maximum of 80 characters.',
    },
    group: SYNOPSIS,
  },

  // ESSENTIAL
  channel: {
    group: ESSENTIAL,
    type: 'DROPDOWN_INPUT',
    props: {
      label: 'Channel',
      inputProps: {
        options: [
          { label: 'none', value: null },
          ...(function getChannelDropdown() {
            if (isUniversumCustomer) {
              return getLeonineChannel()
            } else if (isNetzkinoCustomer) {
              return [
                {
                  label: 'Netzkino',
                  value: 'netzkino',
                },
                {
                  label: 'CAPI',
                  value: 'capi',
                },
              ]
            } else if (isFilmhomeCustomer) {
              return [
                {
                  label: 'Filmhome Default (Scream)', // TODO: FH-21
                  value: 'filmhome',
                },
              ]
            } else if (isArdCustomer) {
              return channelsOption_ARD
            } else {
              const c = SMPL_CUSTOMER.replace('-dev', '')
              return [
                {
                  label: upperFirst(c),
                  value: c,
                },
              ]
            }
          })(),
        ],
      },
      custom_style: [sizeMax, marginTop],
    },
  },
  activeCountries: {
    group: ESSENTIAL,
    type: 'STRING_ARRAY_SELECT_INPUT',
    props: {
      label: 'Active Countries',
      inputProps: {
        isCountry: true,
        options: countryOptions,
      },
    },
  },
  customDataSource: { group: SMART_CATEGORY },

  // INFO Group 2
  // STATUS

  // IMAGE
  masterUrl: {
    type: 'LONG_TEXT_INPUT',
    props: {
      label: 'Master Url',
      custom_style: [sizeMax],
    },
    group: IMAGE,
  },
  coverImageId: {
    type: 'IMAGE_INPUT',
    props: {
      label: 'Cover Image',
      custom_style: [sizeMax, noBGColor],
    },
    group: IMAGE,
  },
  coverImageBrandedId: {
    type: 'IMAGE_INPUT',
    props: {
      label: 'Cover Image Branded',
      custom_style: [sizeMax, noBGColor],
    },
    group: IMAGE,
  },
  coverImageBranded23Channel1Id: {
    type: 'IMAGE_INPUT',
    props: {
      label: 'Cover Image Branded 2:3 (Channel 1)',
      custom_style: [sizeMax, noBGColor],
    },
    group: isArdCustomer || isFlorianhommCustomer ? IMAGE : NONE,
  },
  widescreenImageId: {
    type: 'IMAGE_INPUT',
    props: {
      label: 'Widescreen Image',
      custom_style: [sizeMax, noBGColor],
    },
    group: IMAGE,
  },
  widescreenImageBrandedId: {
    type: 'IMAGE_INPUT',
    props: {
      label: 'Widescreen Image Branded',
      custom_style: [sizeMax, noBGColor],
    },
    group: IMAGE,
  },
  widescreenImageBranded169Channel1Id: {
    type: 'IMAGE_INPUT',
    props: {
      label: 'Widescreen Image Branded 16:9 (Channel 1)',
      custom_style: [sizeMax, noBGColor],
    },
    group: isArdCustomer || isFlorianhommCustomer ? IMAGE : NONE,
  },
  artLogoImageId: {
    type: 'IMAGE_INPUT',
    props: {
      label: isNetzkinoCustomer ? 'Art Logo Image (440x180)' : 'Art Logo Image',
      custom_style: [sizeMax, noBGColor],
    },
    group: isArdCustomer || isNetzkinoCustomer ? IMAGE : NONE,
  },
  heroLandscapeImageId: {
    type: 'IMAGE_INPUT',
    props: {
      label: isNetzkinoCustomer
        ? 'Hero Landscape Image (1920x1080)'
        : 'Hero Landscape Image',
      custom_style: [sizeMax, noBGColor],
    },
    group: isArdCustomer || isNetzkinoCustomer ? IMAGE : NONE,
  },
  heroPortraitImageId: {
    type: 'IMAGE_INPUT',
    props: {
      label: isNetzkinoCustomer
        ? 'Hero Portrait Image (750x1040)'
        : 'Hero Portrait Image',
      custom_style: [sizeMax, noBGColor],
    },
    group: isArdCustomer || isNetzkinoCustomer ? IMAGE : NONE,
  },
  primaryImageId: {
    type: 'IMAGE_INPUT',
    props: {
      label: isNetzkinoCustomer ? 'Primary Image (860x646)' : 'Primary Image',
      custom_style: [sizeMax, noBGColor],
    },
    group: isArdCustomer || isNetzkinoCustomer ? IMAGE : NONE,
  },
  videoStillImageId: {
    type: 'IMAGE_INPUT',
    props: {
      label: isNetzkinoCustomer
        ? 'Video Still Image (920x1080)'
        : 'Video Still Image',
      custom_style: [sizeMax, noBGColor],
    },
    group: isArdCustomer || isNetzkinoCustomer ? IMAGE : NONE,
  },
  headerImageId: {
    type: 'IMAGE_INPUT',
    props: {
      label: 'Header Image',
      custom_style: [sizeMax, noBGColor],
    },
    group: IMAGE,
  },
  headerImage24By9Id: {
    type: 'IMAGE_INPUT',
    props: {
      label: 'Header Image 24:9',
      custom_style: [sizeMax, noBGColor],
    },
    group: isArdCustomer || isFlorianhommCustomer ? IMAGE : NONE,
  },
  logoId: {
    type: 'IMAGE_INPUT',
    props: {
      label: 'Logo Image',
      custom_style: [sizeMax, noBGColor],
    },
    group: IMAGE,
  },
  customImage920X368Id: {
    type: 'IMAGE_INPUT',
    props: {
      label: 'Custom Image 920x368 (Seven)',
      custom_style: [sizeMax, noBGColor],
    },
    group: isNetzkinoCustomer || isFlorianhommCustomer ? IMAGE : NONE,
  },
  customImage1440X540Id: {
    type: 'IMAGE_INPUT',
    props: {
      label: 'Custom Image 1440x540 (Slider)',
      custom_style: [sizeMax, noBGColor],
    },
    group: isNetzkinoCustomer || isFlorianhommCustomer ? IMAGE : NONE,
  },

  widescreenImageTextlessId: {
    type: 'IMAGE_INPUT',
    props: {
      label: 'Widescreen Image Textless',
      custom_style: [sizeMax, noBGColor],
    },
    group: isArdCustomer || isFlorianhommCustomer ? IMAGE : NONE,
  },
  squareImageId: {
    type: 'IMAGE_INPUT',
    props: {
      label: 'Square Image',
      custom_style: [sizeMax, noBGColor],
    },
    group: isArdCustomer || isFlorianhommCustomer ? IMAGE : NONE,
  },

  // SEO
  seoTitle: {
    type: 'TEXT_INPUT',
    props: {
      label: 'SEO Title',
      inputProps: {
        ...(isUniversumCustomer && { maxLength: 39 }),
      },
      custom_style: [sizeMax],
    },
    group: SEO,
  },
  seoDescription: {
    type: 'TEXT_INPUT',
    props: {
      label: 'SEO Description',
      inputProps: {
        ...(isUniversumCustomer && { maxLength: 160 }),
      },
      custom_style: [sizeMax],
    },
    group: SEO,
  },
  seoTags: {
    type: 'TEXT_INPUT',
    props: {
      label: 'SEO Tags',
      inputProps: {
        ...(isUniversumCustomer && { maxLength: 160 }),
      },
      custom_style: [sizeMax],
    },
    group: SEO,
  },
  seoSharingImageId: {
    type: 'IMAGE_INPUT',
    props: {
      label: 'SEO Sharing Image',
      custom_style: [sizeMax, noBGColor],
    },
    group: SEO,
  },

  // LANGUAGES

  // VIDEOINFO

  // RELATED

  // AWS

  // USER

  // PRODUCT
  productContentAccess: {
    group: PRODUCT,
    ...productContentAccessDropDown,
  },
  price: {
    group: PRODUCT,
    type: 'NUMBER',
    props: {
      label: 'Price',
      custom_style: [sizeMax],
      helperText:
        'The price should be expressed in hundredths. E.g. 3€ = 300 or 300Ft = 30000',
    },
  },
  currencyCode: {
    group: PRODUCT,
    ...productCurrencyDropdown,
  },

  // OTHERS
  omu: {
    group: OTHERS,
    type: 'BOOLEAN_INPUT',
    props: {
      label: 'Original with Subtitles',
    },
  },
  ov: {
    group: OTHERS,
    type: 'BOOLEAN_INPUT',
    props: {
      label: 'Original Version',
    },
  },
  isRecurringActive: {
    group: OTHERS,
    type: 'BOOLEAN_INPUT',
    props: {
      label: 'Is Recurring Active',
      helperText:
        'Set to "false" in the backend if the subscription does not automatically renew every month. Manually changing the value has no effect on the subscription.',
    },
  },
  fskRating: {
    group: OTHERS,
    type: 'NUMBER',
    props: {
      label: 'Age Rating',
      custom_style: [sizeMax],
    },
  },
  rating: {
    group: OTHERS,
    type: 'SLIDER_INPUT',
    props: {
      label: 'Rating',
      inputProps: {
        min: 0.0,
        max: 5.0,
        step: 0.1,
        defaultValue: 2.5,
      },
      custom_style: [sizeMax, noBGColor, marginTop],
    },
  },
  productGroup: {
    group: OTHERS,
    ...productGroupDropDown,
  },
  accessToContentFlag: {
    group: OTHERS,
    ...productContentAccessDropDown,
  },
  productionCountry: {
    group: OTHERS,
    type: 'DROPDOWN_INPUT',
    props: {
      label: 'Production Country',
      multiple: true,
      saveAs: 'string',
      inputProps: {
        // isCountry: true,
        options: countryOptions,
      },
      custom_style: [sizeMax, marginTop],
    },
  },
  platform: {
    group: OTHERS,
    type: 'DROPDOWN_INPUT',
    props: {
      label: 'Platform',
      inputProps: {
        options: [
          { label: 'All Platforms (empty value)', value: null },
          ...(isUniversumCustomer
            ? [
                { label: 'Home Of Horror', value: 'homeofhorror' },
                { label: 'Filmtastic', value: 'filmtastic' },
                { label: 'Arthouse CNMA', value: 'arthousecnma' },
              ]
            : []),
        ],
      },
      custom_style: [sizeMax, marginTop],
    },
  },
  originCountry: {
    group: OTHERS,
    type: 'AUTOCOMPLETE_INPUT',
    props: {
      label: 'Origin Country',
      inputProps: {
        isCountry: true,
        options: countryOptions,
      },
      custom_style: [sizeMax],
    },
  },
  properties: {
    group: OTHERS,
    type: 'STRING_ARRAY_SELECT_INPUT',
    props: {
      label: 'Properties',
      inputProps: {
        options: propertiesOptions,
      },
    },
  },
  channels: {
    group: isNetzkinoCustomer || isArdCustomer ? OTHERS : NONE,
    type: 'STRING_ARRAY_SELECT_INPUT',
    props: {
      label: 'Channels',
      inputProps: {
        options: [
          ...((isNetzkinoCustomer && channelsOption_NK) ||
            (isArdCustomer && channelsOption_ARD) ||
            []),
        ],
      },
    },
  },
  portals: {
    group: isNetzkinoCustomer ? OTHERS : NONE,
    type: 'STRING_ARRAY_SELECT_INPUT',
    props: {
      label: 'Portals',
      inputProps: {
        options: [...(isNetzkinoCustomer ? portalsOption_NK : [])],
      },
    },
  },
  foreignPortals: {
    group: isNetzkinoCustomer ? OTHERS : NONE,
    type: 'STRING_ARRAY_SELECT_INPUT',
    props: {
      label: 'Foreign Portals',
      inputProps: {
        options: [...(isNetzkinoCustomer ? foreignPortalsOption_NK : [])],
      },
    },
  },
  languages: {
    group: OTHERS,
    type: 'STRING_ARRAY_SELECT_INPUT',
    props: {
      label: 'Languages',
      inputProps: {
        isCountry: true,
        options: countryOptions,
      },
    },
  },
  subtitleLanguages: {
    group: OTHERS,
    type: 'STRING_ARRAY_SELECT_INPUT',
    props: {
      label: 'Subtitle Languages',
      inputProps: {
        isCountry: true,
        options: countryOptions,
      },
    },
  },

  customData: {
    group: OTHERS,
    type: 'CUSTOM_DATA_INPUT',
    props: {
      label: 'Custom Data',
      inputProps: {
        // INFO this is for contract and license for customData of other resources, overwrite these below in corresponding resource
        fields: [
          ...(isNetzkinoCustomer
            ? [
                { name: 'ContractNr', label: 'VERTRAGS NR.' },
                { name: 'ContractNrFlat', label: 'VERTRAGS NR. FLAT' },
                { name: 'SpotfilmUniqueId', label: 'Spotfilm Unique-ID' },
                { name: 'SpotfilmNr', label: 'Spotfilm Nr', type: 'Number' },
                { name: 'LgTitleNr', label: 'LG Titel Nr', type: 'Number' },
                { name: 'LicensorFolder', label: 'Lizenzgeber Ordner' },
                { name: 'share_AVOD', label: 'Share AVOD', type: 'Number' },
                { name: 'share_SVOD', label: 'Share SVOD', type: 'Number' },
                { name: 'VP', label: 'VP', type: 'Number' }, // Vertriebspausche
                { name: 'flat', label: 'Flat' },
                { name: 'offlineDate', label: 'Offline Datum', type: 'Date' },
              ]
            : isArdCustomer
            ? [{ name: 'partnerName', label: 'Partner Name' }]
            : []),
        ],
      },
    },
  },

  // ADVANCED
  videoSource: {
    group: ADVANCED,
    type: 'CUSTOM_DATA_INPUT',
    props: {
      label: 'Video Source',
      inputProps: {
        saveType: 'object',
        fields: [
          { name: 'hlsUrl', label: 'HLS Url' },
          { name: 'hlsContentId', label: 'HLS Content Id' },

          { name: 'dashUrl', label: 'Dash Url' },
          { name: 'dashContentId', label: 'Dash Content Id' },

          { name: 'pmdUrl', label: 'Film-Url' },

          { name: '__typename', label: 'Object Type' },
        ],
      },
    },
  },
  ...(isNetzkinoCustomer || isKixiCustomer
    ? {
        dashAudioInfo: { group: NONE },
        hlsAudioInfo: { group: NONE },
        dashTextTrackInfo: { group: NONE },
        hlsTextTrackInfo: { group: NONE },
      }
    : {
        dashAudioInfo: { group: ADVANCED },
        hlsAudioInfo: { group: ADVANCED },
        dashTextTrackInfo: { group: ADVANCED },
        hlsTextTrackInfo: { group: ADVANCED },
      }),

  value: {
    type: isFlexgoldCustomer ? 'LONG_TEXT_INPUT' : 'TEXT_INPUT',
  },

  // COMMENT
  systemNotifications: { group: NONE }, // do not display this in create/edit page
}

const showTypeName = {
  ...createShowEditFieldGrouping,
  // FieldDefinition:
  masterUrl: {
    type: 'URL_FIELD_PIC',
    props: {},
  },
  productionYear: singleLineTextField,
  id: ignoreMaxLengthTextField,
  // this is for universum:
  isActive: {
    type: 'ISACTIVE',
    group: STATUS,
    props: isActiveProps,
  },
}

export const createEditTypeNameKeysArray = Object.keys(createEditTypeName)

export type CustomInputProps = {
  targetField?: string
  label?: string
  keyPath?: string
  source?: string
  track?: string
  maxWidth?: boolean
  inputProps?: any
  custom_style?: string[]
  helperText?: string
}

export type FieldDefinition = {
  type?: string
  // custom field renderes can have any props
  props?: CustomInputProps & Record<string, any>
  group?: string
}

type ListFieldDefinition = {
  type?: string
  props?: object
  group?: string
}

type Settings = {
  fields: { [fieldName in string]: FieldDefinition }
}

type ListSettings = {
  fields: { [fieldName in string]: ListFieldDefinition }
}

export type ManyToManyOverrides = {
  alias: string // category
  filterPath: string[] // cmsMovieContentCategoriesByContentMovieId, some, contentMovie
  filterTemplate: string
}

type FilterSettings = {
  manyToManyRelations: ManyToManyOverrides[]
}

type PageDefinition = {
  list: ListSettings
  show: Settings
  editAndCreate: Settings
  filter?: FilterSettings
}

const MovieFlagsField = {
  group: OTHERS,
  type: 'FLAGS_FIELD',
  props: {
    label: 'Flags',
    inputProps: {
      flagData: MovieFlagsData,
    },
  },
}

const MovieFlagsInput = {
  group: OTHERS,
  type: 'FLAGS_INPUT',
  props: {
    label: 'Flags',
    inputProps: {
      flagData: MovieFlagsData,
    },
  },
}

const ArdFeedObjectCustomDataFields = [
  // string
  { name: 'partnerName', label: 'Partner Name' },
  { name: 'studioNetwork', label: 'Studio Network' },
  { name: 'lra', label: 'Landesrundfunkanstalt' },
  { name: 'tags', label: 'Tags' }, // INFO until the tags is added for real
  { name: 'specialCategory', label: 'Special Category' },
  { name: 'team', label: 'Team' },
  { name: 'location', label: 'Location' },
  {
    name: 'seriesExternalIdentifier',
    label: 'Series External Id',
  },
  {
    name: 'seasonExternalIdentifier',
    label: 'Season External Id',
  },
  // number
  { name: 'seasonNumber', label: 'Season Number' },
  { name: 'episodeNumber', label: 'Episode Number' },
  {
    name: 'episodeProductionNumber',
    label: 'Episode Production Number',
  },
  // date
  {
    name: 'originalReleaseDate',
    label: 'Original Release Date',
    type: 'Date',
  },
  // select
  {
    name: 'productPlacement',
    label: 'Product Placement',
    type: 'Select',
    options: [
      { label: 'No Value', value: null },
      {
        label: 'Yes',
        value: 'yes',
      },
      {
        label: 'No',
        value: 'no',
      },
    ].filter(Boolean),
  },
  {
    name: 'pse',
    label: 'Photo Sensitive Epilepsie',
    type: 'Select',
    options: [
      { label: 'No Value', value: null },
      {
        label: 'Yes',
        value: 'yes',
      },
      {
        label: 'No',
        value: 'no',
      },
    ].filter(Boolean),
  },
  {
    name: 'preRoll',
    label: 'Pre Roll',
    type: 'Select',
    options: [
      { label: 'No Value', value: null },
      {
        label: 'Yes',
        value: true,
      },
      {
        label: 'No',
        value: false,
      },
    ].filter(Boolean),
  },
  // values based on this: https://help.apple.com/itc/tvpumcspecifications/#/da0c5ca0735f
  {
    name: 'movieInfoType',
    label: 'Movie Info Type',
    type: 'Select',
    options: [
      {
        label: 'No Value (default to tv_movie)',
        value: null,
      },
      {
        label: 'tv_movie',
        value: 'tv_movie',
      },
      {
        label: 'feature',
        value: 'feature',
      },
      {
        label: 'short',
        value: 'short',
      },
    ].filter(Boolean),
  },
  {
    name: 'movieInfoVersion',
    label: 'Movie Info Version',
    type: 'Select',
    options: [
      {
        label: 'No Value (default to standard)',
        value: null,
      },
      {
        label: 'standard',
        value: 'standard',
      },
      {
        label: 'unrated',
        value: 'unrated',
      },
      {
        label: 'rated',
        value: 'rated',
      },
      {
        label: 'uncut',
        value: 'uncut',
      },
      {
        label: 'directors_cut',
        value: 'directors_cut',
      },
      {
        label: 'extended',
        value: 'extended',
      },
      {
        label: 'remastered',
        value: 'remastered',
      },
      {
        label: 'special_edition',
        value: 'special_edition',
      },
      {
        label: 'alternate_ending',
        value: 'alternate_ending',
      },
      {
        label: 'alternate_cut',
        value: 'alternate_cut',
      },
      {
        label: 'edited_for_tv',
        value: 'edited_for_tv',
      },
    ].filter(Boolean),
  },
  {
    name: 'showInfoType',
    label: 'Show Info Type',
    type: 'Select',
    options: [
      {
        label: 'No Value (default to series)',
        value: null,
      },
      {
        label: 'series',
        value: 'series',
      },
      {
        label: 'miniseries',
        value: 'miniseries',
      },
      {
        label: 'seasonless',
        value: 'seasonless',
      },
    ].filter(Boolean),
  },

  // img
  {
    name: 'cover_artwork_vertical',
    label: 'cover_artwork_vertical',
    type: 'Image',
  },
  {
    name: 'cover_artwork_horizontal',
    label: 'cover_artwork_horizontal',
    type: 'Image',
  },
  {
    name: 'tile_artwork',
    label: 'tile_artwork',
    type: 'Image',
  },
  {
    name: 'centered_background_wide',
    label: 'centered_background_wide',
    type: 'Image',
  },
  {
    name: 'centered_background_tall',
    label: 'centered_background_tall',
    type: 'Image',
  },
  {
    name: 'artwork_horizontal_background_169',
    label: 'artwork_horizontal_background_169',
    type: 'Image',
  },
  {
    name: 'artwork_horizontal_background_249',
    label: 'artwork_horizontal_background_249',
    type: 'Image',
  },
  // json
  { name: 'foreignTitle', label: 'Foreign Title', type: 'Json' },
  { name: 'person', label: 'Person', type: 'Json' },
]

// if a ressource is not explicitely defined then it will use this default setting:
export const defaultListShowEditAndCreate: PageDefinition = {
  list: {
    fields: { ...createShowEditFieldGrouping, ...listTypeName },
  },
  show: {
    fields: {
      ...showTypeName,
    },
  },
  editAndCreate: {
    fields: { ...createEditTypeName },
  },
}

export const TypeDisplaySetting: {
  [dataTypeName in string]?: PageDefinition
} = {
  CmsCategory: {
    list: {
      fields: { ...listTypeName },
    },
    show: {
      fields: {
        ...showTypeName,
        'custom:sortableCategoryList': {
          type: 'SORTABLE_CATEGORY_LIST',
          props: {
            label: '',
          },
        },
        'custom:sortableMovieList': {
          type: 'SORTABLE_MOVIE_LIST',
          props: {
            label: '',
          },
        },
        'custom:catAllocation': {
          type: 'CAT_ALLOCATION',
          props: {
            label: '',
          },
        },
        flags: {
          group: OTHERS,
          type: 'FLAGS_FIELD',
          props: {
            label: 'Flags',
            inputProps: {
              flagData: CategoryFlagsData,
            },
          },
        },
      },
    },
    editAndCreate: {
      fields: {
        ...createEditTypeName,
        flags: {
          group: OTHERS,
          type: 'FLAGS_INPUT',
          props: {
            label: 'Flags',
            inputProps: {
              flagData: CategoryFlagsData,
            },
          },
        },
        customDataSource: {
          group: SMART_CATEGORY,
          type: 'SMART_CATEGORY_INPUT',
          props: {
            label: 'Filter Rules',
            inputProps: { data: createEditTypeName.customDataSource },
          },
        },
      },
    },
  },
  CmsClip: {
    filter: {
      manyToManyRelations: [
        {
          alias: 'person connection',
          filterPath: [
            'cmsMovieContentPeopleByContentClipId',
            'some',
            'person',
          ],
          filterTemplate: `{"cmsMovieContentPeopleByContentClipId": {"some": { "person": $$ }}}`,
        },
        {
          alias: 'actor connection',
          filterPath: [
            'cmsMovieContentPeopleByContentClipId',
            'some',
            'person',
          ],
          filterTemplate: `{"cmsMovieContentPeopleByContentClipId": {"some": { "connectionType":{"equalTo":"actor"}, "person": $$ }}}`,
        },
        {
          alias: 'director connection',
          filterPath: [
            'cmsMovieContentPeopleByContentClipId',
            'some',
            'person',
          ],
          filterTemplate: `{"cmsMovieContentPeopleByContentClipId": {"some": { "connectionType":{"equalTo":"director"}, "person": $$ }}}`,
        },
        {
          alias: 'writer connection',
          filterPath: [
            'cmsMovieContentPeopleByContentClipId',
            'some',
            'person',
          ],
          filterTemplate: `{"cmsMovieContentPeopleByContentClipId": {"some": { "connectionType":{"equalTo":"writer"}, "person": $$ }}}`,
        },
      ],
    },
    list: {
      fields: { ...listTypeName },
    },
    show: {
      fields: {
        ...showTypeName,
        'custom:videoSource': {
          type: 'VIDEOSOURCE',
          props: {
            targetField: 'videoSource', // use this to identify which Object Field we are targeting
            label: 'Video Source',
          },
        },
        'custom:videoPlayer': {
          type: 'VIDEOPLAYER',
          props: {
            label: 'Videoplayer',
            keyPath: 'URL',
          },
        },
        flags: MovieFlagsField,
      },
    },
    editAndCreate: {
      fields: {
        ...createEditTypeName,
        flags: MovieFlagsInput,
      },
    },
  },
  CmsContract: {
    list: {
      fields: { ...listTypeName },
    },
    show: {
      fields: {
        ...showTypeName,
        licensorId: { group: RELATED },
      },
    },
    editAndCreate: {
      fields: {
        ...createEditTypeName,
        licensorId: { group: RELATED },
      },
    },
  },
  CmsEpisode: {
    filter: {
      manyToManyRelations: [
        {
          alias: 'person connection',
          filterPath: [
            'cmsMovieContentPeopleByContentEpisodeId',
            'some',
            'person',
          ],
          filterTemplate: `{"cmsMovieContentPeopleByContentEpisodeId": {"some": { "person": $$ }}}`,
        },
        {
          alias: 'actor connection',
          filterPath: [
            'cmsMovieContentPeopleByContentEpisodeId',
            'some',
            'person',
          ],
          filterTemplate: `{"cmsMovieContentPeopleByContentEpisodeId": {"some": { "connectionType":{"equalTo":"actor"}, "person": $$ }}}`,
        },
        {
          alias: 'director connection',
          filterPath: [
            'cmsMovieContentPeopleByContentEpisodeId',
            'some',
            'person',
          ],
          filterTemplate: `{"cmsMovieContentPeopleByContentEpisodeId": {"some": { "connectionType":{"equalTo":"director"}, "person": $$ }}}`,
        },
        {
          alias: 'writer connection',
          filterPath: [
            'cmsMovieContentPeopleByContentEpisodeId',
            'some',
            'person',
          ],
          filterTemplate: `{"cmsMovieContentPeopleByContentEpisodeId": {"some": { "connectionType":{"equalTo":"writer"}, "person": $$ }}}`,
        },
      ],
    },
    list: {
      fields: { ...listTypeName },
    },
    show: {
      fields: {
        ...showTypeName,
        'custom:videoSource': {
          type: 'VIDEOSOURCE',
          props: {
            targetField: 'videoSource', // use this to identify which Object Field we are targeting
            label: 'Video Source',
          },
        },
        'custom:videoPlayer': {
          type: 'VIDEOPLAYER',
          props: {
            label: 'Videoplayer',
            keyPath: 'URL',
          },
        },
        flags: MovieFlagsField,
      },
    },
    editAndCreate: {
      fields: {
        ...createEditTypeName,
        flags: MovieFlagsInput,
        customData: {
          group: OTHERS,
          type: 'CUSTOM_DATA_INPUT',
          props: {
            label: 'Custom Data Episode',
            inputProps: {
              fields: [
                ...(isArdCustomer
                  ? [
                      {
                        name: 'productPlacement',
                        label: 'Product Placement',
                        type: 'Select',
                        options: [
                          {
                            label: 'yes',
                            value: 'yes',
                          },
                          {
                            label: 'no',
                            value: 'no',
                          },
                        ].filter(Boolean),
                      },
                      {
                        name: 'drmEncrypted',
                        label: 'DRM Encrypted',
                        type: 'Boolean',
                      },
                    ]
                  : []),
              ],
            },
          },
        },
      },
    },
  },
  CmsMovie: {
    filter: {
      manyToManyRelations: [
        {
          alias: 'category connections', // prevent wrong selection due to cmsCategoriesByLinked... filters
          filterPath: [
            'cmsMovieContentCategoriesByContentMovieId',
            'some',
            'category',
          ],
          filterTemplate: `{ "cmsMovieContentCategoriesByContentMovieId": {"some": { "category": $$ }}}`,
        },
        {
          alias: 'person connection',
          filterPath: [
            'cmsMovieContentPeopleByContentMovieId',
            'some',
            'person',
          ],
          filterTemplate: `{"cmsMovieContentPeopleByContentMovieId": {"some": { "person": $$ }}}`,
        },
        {
          alias: 'actor connection',
          filterPath: [
            'cmsMovieContentPeopleByContentMovieId',
            'some',
            'person',
          ],
          filterTemplate: `{"cmsMovieContentPeopleByContentMovieId": {"some": { "connectionType":{"equalTo":"actor"}, "person": $$ }}}`,
        },
        {
          alias: 'director connection',
          filterPath: [
            'cmsMovieContentPeopleByContentMovieId',
            'some',
            'person',
          ],
          filterTemplate: `{"cmsMovieContentPeopleByContentMovieId": {"some": { "connectionType":{"equalTo":"director"}, "person": $$ }}}`,
        },
        {
          alias: 'writer connection',
          filterPath: [
            'cmsMovieContentPeopleByContentMovieId',
            'some',
            'person',
          ],
          filterTemplate: `{"cmsMovieContentPeopleByContentMovieId": {"some": { "connectionType":{"equalTo":"writer"}, "person": $$ }}}`,
        },
      ],
    },
    list: {
      fields: { ...listTypeName },
    },
    show: {
      fields: {
        ...showTypeName,
        'custom:categories': {
          group: RELATED,
          type: 'CATEGORIES',
          props: {
            label: 'Categories',
          },
        },
        'custom:videoSource': {
          type: 'VIDEOSOURCE',
          props: {
            targetField: 'videoSource', // use this to identify which Object Field we are targeting
            label: 'Video Source',
          },
        },
        'custom:updateRating': {
          type: 'UPDATE_RATING',
          props: {
            label: 'Update rating with IMDb rating and amplifier',
          },
        },
        'custom:videoPlayer': {
          type: 'VIDEOPLAYER',
          props: {
            label: 'Videoplayer',
            keyPath: 'URL',
          },
        },
        flags: MovieFlagsField,
        videoStillImageId: {
          group: isArdCustomer || isNetzkinoCustomer ? IMAGE : NONE,
        },
      },
    },
    editAndCreate: {
      fields: {
        ...createEditTypeName,
        flags: MovieFlagsInput,
        customData: {
          group: OTHERS,
          type: 'CUSTOM_DATA_INPUT',
          props: {
            label: 'Custom Data Movie',
            inputProps: {
              fields: [
                ...(isArdCustomer
                  ? [
                      {
                        name: 'productPlacement',
                        label: 'Product Placement',
                        type: 'Select',
                        options: [
                          {
                            label: 'yes',
                            value: 'yes',
                          },
                          {
                            label: 'no',
                            value: 'no',
                          },
                        ].filter(Boolean),
                      },
                      {
                        name: 'drmEncrypted',
                        label: 'DRM Encrypted',
                        type: 'Boolean',
                      },
                    ]
                  : []),
                { name: 'awards', label: 'Awards' },
                { name: 'plot_keywords', label: 'Plot Keywords' },
                { name: 'aka', label: 'Also Known As' }, // own field ???
              ],
            },
          },
        },
        subtitleLanguages: { group: NONE },
        videoStillImageId: {
          group: isArdCustomer || isNetzkinoCustomer ? IMAGE : NONE,
          type: 'IMAGE_INPUT',
          props: {
            label: isNetzkinoCustomer
              ? 'Video Still Image (920x1080)'
              : 'Video Still Image',
            custom_style: [sizeMax, noBGColor],
          },
        },
      },
    },
  },
  CmsFeedObject: {
    list: {
      fields: { ...listTypeName },
    },
    show: {
      fields: {
        ...showTypeName,
        'custom:categories': {
          group: RELATED,
          type: 'CATEGORIES',
          props: {
            label: 'Categories',
          },
        },
        'custom:videoSource': {
          group: OTHERS,
          type: 'VIDEOSOURCE',
          props: {
            targetField: 'videoSource', // use this to identify which Object Field we are targeting
            label: 'Video Source',
          },
        },
        'custom:updateRating': {
          type: 'UPDATE_RATING',
          props: {
            label: 'Update rating with IMDb rating and amplifier',
          },
        },
        'custom:videoPlayer': {
          type: 'VIDEOPLAYER',
          props: {
            label: 'Videoplayer',
            keyPath: 'URL',
          },
        },
        flags: MovieFlagsField,
        customData: {
          group: CUSTOM_DATA,
          type: 'CUSTOM_DATA_FIELD',
          props: {
            label: 'Custom Data',
            maxWidth: true,
            inputProps: {
              fields: [...(isArdCustomer ? ArdFeedObjectCustomDataFields : [])],
            },
          },
        },
      },
    },
    editAndCreate: {
      fields: {
        ...createEditTypeName,
        flags: MovieFlagsInput,
        customData: {
          group: CUSTOM_DATA,
          type: 'CUSTOM_DATA_INPUT',
          props: {
            label: 'Custom Data',
            maxWidth: true,
            inputProps: {
              fields: [...(isArdCustomer ? ArdFeedObjectCustomDataFields : [])],
            },
          },
        },
        subtitleLanguages: { group: NONE },
        videoSource: { group: NONE },
        dashAudioInfo: { group: NONE },
        hlsAudioInfo: { group: NONE },
        dashTextTrackInfo: { group: NONE },
        hlsTextTrackInfo: { group: NONE },
        rating: { group: NONE },
        seoTitle: {
          group: NONE,
        },
        seoDescription: {
          group: NONE,
        },
        seoTags: { group: NONE },
        seoSharingImageId: { group: NONE },
        slug: { group: NONE },
        isan: { group: NONE },
      },
    },
  },
  CmsSeason: {
    filter: {
      manyToManyRelations: [
        {
          alias: 'person connection',
          filterPath: [
            'cmsMovieContentPeopleByContentSeasonId',
            'some',
            'person',
          ],
          filterTemplate: `{"cmsMovieContentPeopleByContentSeasonId": {"some": { "person": $$ }}}`,
        },
        {
          alias: 'actor connection',
          filterPath: [
            'cmsMovieContentPeopleByContentSeasonId',
            'some',
            'person',
          ],
          filterTemplate: `{"cmsMovieContentPeopleByContentSeasonId": {"some": { "connectionType":{"equalTo":"actor"}, "person": $$ }}}`,
        },
        {
          alias: 'director connection',
          filterPath: [
            'cmsMovieContentPeopleByContentSeasonId',
            'some',
            'person',
          ],
          filterTemplate: `{"cmsMovieContentPeopleByContentSeasonId": {"some": { "connectionType":{"equalTo":"director"}, "person": $$ }}}`,
        },
        {
          alias: 'writer connection',
          filterPath: [
            'cmsMovieContentPeopleByContentSeasonId',
            'some',
            'person',
          ],
          filterTemplate: `{"cmsMovieContentPeopleByContentSeasonId": {"some": { "connectionType":{"equalTo":"writer"}, "person": $$ }}}`,
        },
      ],
    },
    list: {
      fields: { ...listTypeName },
    },
    show: {
      fields: {
        ...showTypeName,
        flags: MovieFlagsField,
      },
    },
    editAndCreate: {
      fields: {
        ...createEditTypeName,
        flags: MovieFlagsInput,
        customData: {
          group: OTHERS,
          type: 'CUSTOM_DATA_INPUT',
          props: {
            label: 'Custom Data Season',
            inputProps: {
              fields: [
                ...(isArdCustomer
                  ? [
                      {
                        name: 'drmEncrypted',
                        label: 'DRM Encrypted',
                        type: 'Boolean',
                      },
                    ]
                  : []),
              ],
            },
          },
        },
      },
    },
  },
  CmsSery: {
    filter: {
      manyToManyRelations: [
        {
          alias: 'category connection', // prevent wrong selection due to cmsCategoriesByLinked... filters
          filterPath: [
            'cmsMovieContentCategoriesByContentSeriesId',
            'some',
            'category',
          ],
          filterTemplate: `{ "cmsMovieContentCategoriesByContentSeriesId": {"some": { "category": $$ }}}`,
        },
        {
          alias: 'person connection',
          filterPath: [
            'cmsMovieContentPeopleByContentSeriesId',
            'some',
            'person',
          ],
          filterTemplate: `{"cmsMovieContentPeopleByContentSeriesId": {"some": { "person": $$ }}}`,
        },
        {
          alias: 'actor connection',
          filterPath: [
            'cmsMovieContentPeopleByContentSeriesId',
            'some',
            'person',
          ],
          filterTemplate: `{"cmsMovieContentPeopleByContentSeriesId": {"some": { "connectionType":{"equalTo":"actor"}, "person": $$ }}}`,
        },
        {
          alias: 'director connection',
          filterPath: [
            'cmsMovieContentPeopleByContentSeriesId',
            'some',
            'person',
          ],
          filterTemplate: `{"cmsMovieContentPeopleByContentSeriesId": {"some": { "connectionType":{"equalTo":"director"}, "person": $$ }}}`,
        },
        {
          alias: 'writer connection',
          filterPath: [
            'cmsMovieContentPeopleByContentSeriesId',
            'some',
            'person',
          ],
          filterTemplate: `{"cmsMovieContentPeopleByContentSeriesId": {"some": { "connectionType":{"equalTo":"writer"}, "person": $$ }}}`,
        },
      ],
    },
    list: {
      fields: { ...listTypeName },
    },
    show: {
      fields: {
        ...showTypeName,
        'custom:categories': {
          group: RELATED,
          type: 'CATEGORIES',
          props: {
            label: 'Categories',
          },
        },
        'custom:updateRating': {
          type: 'UPDATE_RATING',
          props: {
            label: 'Update rating with IMDb rating and amplifier',
          },
        },
        flags: MovieFlagsField,
        videoStillImageId: {
          group: isNetzkinoCustomer ? IMAGE : NONE,
        },
      },
    },
    editAndCreate: {
      fields: {
        ...createEditTypeName,
        flags: MovieFlagsInput,
        customData: {
          group: OTHERS,
          type: 'CUSTOM_DATA_INPUT',
          props: {
            label: 'Custom Data Series',
            inputProps: {
              fields: [
                ...(isArdCustomer
                  ? [
                      {
                        name: 'drmEncrypted',
                        label: 'DRM Encrypted',
                        type: 'Boolean',
                      },
                    ]
                  : []),
              ],
            },
          },
        },
        videoStillImageId: {
          group: isNetzkinoCustomer ? IMAGE : NONE,
          type: 'IMAGE_INPUT',
          props: {
            label: isNetzkinoCustomer
              ? 'Video Still Image (920x1080)'
              : 'Video Still Image',
            custom_style: [sizeMax, noBGColor],
          },
        },
      },
    },
  },
  CmsTrailer: {
    list: {
      fields: { ...listTypeName },
    },
    show: {
      fields: {
        ...showTypeName,
        flags: MovieFlagsField,
      },
    },
    editAndCreate: {
      fields: {
        ...createEditTypeName,
        flags: MovieFlagsInput,
      },
    },
  },
  VoucherCampaign: {
    list: {
      fields: { ...listTypeName },
    },
    show: {
      fields: {
        ...showTypeName,
        'custom:generateVouchers': {
          type: 'GENERATE_VOUCHERS',
          props: {
            label: 'Generate vouchers',
          },
        },
        'custom:prodAllocation': {
          type: 'PROD_ALLOCATION',
          props: {
            label: '',
          },
        },
      },
    },
    editAndCreate: {
      fields: {
        ...createEditTypeName,
        type: {
          group: OTHERS,
          type: 'DROPDOWN_INPUT',
          props: {
            label: 'type',
            inputProps: {
              options: [
                { label: 'none (Free Subscription Days)', value: null },
                { label: 'Extended Trial (30 Days)', value: 'EXTENDED_TRIAL' },
                IS_NETZKINO_LIKE
                  ? {
                      label: 'Access to external movie',
                      value: 'FREE_PUBLIC_ACCESS_MOVIE',
                    }
                  : null,
              ].filter(Boolean),
            },
            custom_style: [sizeMax, marginTop],
          },
        },
      },
    },
  },
  TranscodingJob: {
    list: {
      fields: { ...listTypeName },
    },
    show: {
      fields: {
        'custom:mediaFormat': {
          type: 'JSON_EXTRACT_MEDIAINFO',
          props: {
            label: 'Media Format',
            source: 'mediaInfo',
            keyPath: 'Format',
            track: 'General',
          },
        },
        'custom:videoPlayer': {
          type: 'VIDEOPLAYER',
          props: {
            label: 'Videoplayer',
            keyPath: 'URL',
          },
        },
        'custom:comment': {
          type: 'COMMENT',
          props: {
            label: 'Comment',
            keyPath: 'comment',
          },
        },
        'custom:checks': {
          type: 'JSON_EXTRACT_METADATA_CHECKS',
          props: {
            label: 'Status Checks',
            source: 'metadata',
          },
        },
        'custom:assets': {
          type: 'JSON_EXTRACT_METADATA_ASSETS',
          props: {
            label: 'Assets',
            source: 'metadata',
            keyPath: 'assets',
          },
        },
      },
    },
    editAndCreate: {
      fields: {
        ...createEditTypeName,
        status: {
          group: STATUS,
          type: 'DROPDOWN_INPUT',
          props: {
            label: 'Status',
            inputProps: {
              options: [
                { label: 'READY_FOR_QA', value: 'READY_FOR_QA' },
                { label: 'COMPLETED', value: 'COMPLETED' },
                { label: 'ERROR', value: 'ERROR' },
              ],
            },
            custom_style: [sizeMax, marginTop],
          },
        },
      },
    },
  },
  User: {
    list: {
      fields: { ...listTypeName },
    },
    show: {
      fields: {
        ...showTypeName,
      },
    },
    editAndCreate: {
      fields: {
        ...createEditTypeName,
        role: {
          group: IDENTIFICATION,
          type: 'DROPDOWN_INPUT',
          props: {
            label: 'Role',
            inputProps: {
              // we will overwrite this inside the DROPDOWN_INPUT, as we can't access current role here
              // see OptionSetting.ts
              options: [{ label: 'User', value: 'user' }],
            },
            custom_style: [sizeMax, marginTop],
          },
        },
      },
    },
  },
  Vault: {
    list: {
      fields: { ...listTypeName },
    },
    show: {
      fields: {
        ...showTypeName,
      },
    },
    editAndCreate: {
      fields: {
        ...createEditTypeName,
        role: {
          group: OTHERS,
          type: 'DROPDOWN_INPUT',
          props: {
            label: 'Admin Defined Vault Level',
            inputProps: {
              options: [
                { label: 'None', value: 'None' },
                { label: 'Basic', value: 'Basic' },
                { label: 'Advanced', value: 'Advanced' },
                { label: 'Superior', value: 'Superior' },
                { label: 'Institutional', value: 'Institutional' },
              ],
            },
            custom_style: [sizeMax, marginTop],
          },
        },
      },
    },
  },
  PaymentInvoice: {
    list: {
      fields: { ...listTypeName },
    },
    show: {
      fields: {
        ...showTypeName,
        productGroup: {
          group: PRODUCT,
          ...productGroupDropDown,
        },
      },
    },
    editAndCreate: {
      fields: {
        ...createEditTypeName,
        productGroup: {
          group: PRODUCT,
          ...productGroupDropDown,
        },
      },
    },
  },
  ImportRequest: {
    list: {
      fields: { ...listTypeName },
    },
    show: {
      fields: {
        ...showTypeName,
        status: { group: OTHERS },
      },
    },
    editAndCreate: {
      fields: { ...createEditTypeName, status: { group: OTHERS } },
    },
  },
  SubscriptionTerm: {
    list: {
      fields: { ...listTypeName },
    },
    show: {
      fields: {
        ...showTypeName,
        status: { group: OTHERS },
      },
    },
    editAndCreate: {
      fields: {
        ...createEditTypeName,
        status: {
          group: OTHERS,
          type: 'DROPDOWN_INPUT',
          props: {
            label: 'Status',
            inputProps: {
              options: [
                { label: 'VALID', value: 'VALID' },
                { label: 'CANCELED', value: 'CANCELED' },
              ],
            },
            custom_style: [sizeMax, marginTop],
          },
        },
      },
    },
  },
  SessionPlayback: {
    list: {
      fields: { ...listTypeName },
    },
    show: {
      fields: {
        ...showTypeName,
        status: { group: STATUS },
      },
    },
    editAndCreate: {
      fields: {
        ...createEditTypeName,
        status: {
          group: STATUS,
          type: 'DROPDOWN_INPUT',
          props: {
            label: 'Status',
            inputProps: {
              options: [
                { label: 'ACTIVE', value: 'active' },
                { label: 'INACTIVE', value: 'inactive' },
              ],
            },
            custom_style: [sizeMax, marginTop],
          },
        },
      },
    },
  },
  PaymentTransaction: {
    list: {
      fields: { ...listTypeName },
    },
    show: {
      fields: {
        ...showTypeName,
        status: { group: STATUS },
      },
    },
    editAndCreate: {
      fields: {
        ...createEditTypeName,
        status: {
          group: STATUS,
          type: 'DROPDOWN_INPUT',
          props: {
            label: 'Status',
            inputProps: {
              // see DB PaymentStatus
              options: [
                { label: 'SUCCESSFUL', value: 'SUCCESSFUL' },
                { label: 'PENDING', value: 'PENDING' },
                { label: 'INITIALIZED', value: 'INITIALIZED' },
                { label: 'ERROR', value: 'ERROR' },
                { label: 'CANCELLED', value: 'CANCELLED' },
                { label: 'ABORTED', value: 'ABORTED' },
              ],
            },
            custom_style: [sizeMax, marginTop],
          },
        },
      },
    },
  },
  PaymentTransactionLog: {
    list: {
      fields: { ...listTypeName },
    },
    show: {
      fields: {
        ...showTypeName,
        status: { group: STATUS },
      },
    },
    editAndCreate: {
      fields: {
        ...createEditTypeName,
        status: {
          group: STATUS,
          type: 'DROPDOWN_INPUT',
          props: {
            label: 'Status',
            inputProps: {
              // see DB PaymentStatus
              options: [
                { label: 'SUCCESSFUL', value: 'SUCCESSFUL' },
                { label: 'PENDING', value: 'PENDING' },
                { label: 'INITIALIZED', value: 'INITIALIZED' },
                { label: 'ERROR', value: 'ERROR' },
                { label: 'CANCELLED', value: 'CANCELLED' },
                { label: 'ABORTED', value: 'ABORTED' },
              ],
            },
            custom_style: [sizeMax, marginTop],
          },
        },
      },
    },
  },
  PaymentScheduledCancellation: {
    list: {
      fields: { ...listTypeName },
    },
    show: {
      fields: {
        ...showTypeName,
        status: { group: OTHERS },
      },
    },
    editAndCreate: {
      fields: {
        ...createEditTypeName,
        userId: {
          group: RELATED,
          props: {
            helperText:
              'On selecting a subscription, the userId field is automatically set.',
          },
        },
      },
    },
  },
  InsightsDatum: {
    list: {
      fields: { ...listTypeName },
    },
    show: {
      fields: {
        ...showTypeName,
      },
    },
    editAndCreate: {
      fields: {
        movieId: { group: NONE },
        episodeId: { group: NONE },
        licenseId: { group: NONE },
        totalDurationOfViews: { group: NONE },
        numberOfViews: { group: NONE },
        insightsYear: { group: NONE },
        insightsMonth: { group: NONE },
        customData: { group: NONE },
      },
    },
  },
}

function getLeonineChannel() {
  type BaseChannel = 'homeofhorror' | 'arthousecnma' | 'filmtastic'
  const leonineChannelNames: BaseChannel[] = [
    'homeofhorror',
    'arthousecnma',
    'filmtastic',
  ]
  const leonineForeignPortals = ['hdaustria', 'waipu', 'zattoo']
  const channelNameMapping: { [key in BaseChannel]: string } = {
    homeofhorror: 'Home Of Horror',
    arthousecnma: 'Arthouse CNMA',
    filmtastic: 'Filmtastic',
  }

  const channelNamesForForeignPortals = []
  for (const portal of leonineForeignPortals) {
    for (const baseChannel of leonineChannelNames) {
      channelNamesForForeignPortals.push({
        label: `${channelNameMapping[baseChannel]} - ${portal}`,
        value: `${baseChannel}-${portal}`,
      })
    }
  }

  const allLeonineChannels = [
    ...leonineChannelNames.map((name) => {
      return {
        label: channelNameMapping[name],
        value: name,
      }
    }),
    ...channelNamesForForeignPortals,
  ]
  return allLeonineChannels
}
