import {
  Box,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import BlockRoundedIcon from '@material-ui/icons/BlockRounded'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import MenuIcon from '@material-ui/icons/Menu'
import { Record, useNotify, useRefresh } from 'ra-core'
import { useEffect, useState } from 'react'
import { useDataProvider } from 'react-admin'
import useXmlDownload from '../../hooks/useXmlDownload'
import { isArdCustomer, isUniversumCustomer } from '../../lib/config'
import { CustomActionProps } from '../GenericTypeActions'

export type XmlCreationRequest = {
  externalIdentifier: string
  __typename: 'CmsSeason' | 'CmsMovie'
}

const useStyles = makeStyles((theme) => ({
  actionButton: {
    borderColor: theme.palette.primary.main,
    border: 'solid',
    borderWidth: 1,
    color: theme.palette.primary.main,
    padding: theme.spacing(1, 2), // Padding (top/bottom, left/right)
    borderRadius: theme.spacing(2),
    lineHeight: 1.2, // Small line height, in order to reduce button size in case of label wrap
    transition: 'background-color 0.2s ease-in-out',
    '&:hover': {
      borderColor: theme.palette.primary.light + ' !important',
      color: theme.palette.primary.light + ' !important',
    },
  },
}))

// Utility to construct the episode URL
const getEpisodeUrl = (episodeId: string) =>
  `${process.env.PUBLIC_URL}/CmsEpisode/${episodeId}/show`

// Button for navigating to the next episode
const NextEpisodeButton = (props: CustomActionProps) => {
  // Shorter Button-Labels on Medium. Full Button-Labels on small (since collapsable menu is enabled here) and on large screens (since space...)
  const isMediumScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between('sm', 'lg')
  )
  const classes = useStyles()
  const { data } = props
  const handleClick = () => {
    if (!data?.nextEpisodeId) {
      console.warn('Next episode ID is not available.')
      return
    }
    const url = getEpisodeUrl(data.nextEpisodeId)
    window.location.href = url
  }

  return (
    <Button
      className={classes.actionButton}
      onClick={handleClick}
      endIcon={<ArrowForwardIcon />}
    >
      {isMediumScreen ? 'EPISODE' : 'NEXT EPISODE'}
    </Button>
  )
}

// Button for navigating to the previous episode
const PreviousEpisodeButton = (props: CustomActionProps) => {
  // Shorter Button-Labels on Medium. Full Button-Labels on small (since collapsable menu is enabled here) and on large screens (since space...)
  const isMediumScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between('sm', 'lg')
  )
  const classes = useStyles()
  const { data } = props
  const handleClick = () => {
    if (!data?.previousEpisodeId) {
      console.warn('Previous episode ID is not available.')
      return
    }

    const url = getEpisodeUrl(data.previousEpisodeId)
    window.location.href = url
  }

  return (
    <Button
      className={classes.actionButton}
      onClick={handleClick}
      startIcon={<ArrowBackIcon />} // Icon on the left
    >
      {isMediumScreen ? 'EPISODE' : 'PREVIOUS EPISODE'}
    </Button>
  )
}

const DownloadMetadataXmlButton = (props: CustomActionProps) => {
  const movieActionClasses = useStyles()
  const { data } = props
  const { downloadXmls, loading } = useXmlDownload()
  const notify = useNotify()

  const handleDownload = () => {
    if (!data || !data.externalIdentifier || !data.__typename) {
      notify('Missing required data', 'error')
      return
    }

    const payload = [
      {
        externalIdentifier: data.externalIdentifier,
        __typename: data.__typename,
      },
    ]

    downloadXmls(payload)
  }

  return (
    <Button
      className={movieActionClasses.actionButton}
      startIcon={loading ? <CircularProgress size={20} /> : <DownloadIcon />}
      onClick={handleDownload}
      disabled={loading}
    >
      XML
    </Button>
  )
}

const DisplayAvailabilityAndActions = (props: CustomActionProps) => {
  const movieActionClasses = useStyles()
  const { classes, data, resource } = props
  const [licenseData, setLicenseData] = useState<Record | undefined>()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  const theme = useTheme()

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isMediumScreenOrSmaller = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  )
  const isMediumScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between('sm', 'lg')
  )
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const toggleDrawer = (isOpen: boolean) => {
    setIsDrawerOpen(isOpen)
  }

  useEffect(() => {
    if (data && (resource === 'CmsMovie' || resource === 'CmsEpisode')) {
      const fetchLicenseData = async () => {
        const filterString =
          resource === 'CmsMovie' ? 'contentMovieId' : 'contentEpisodeId'
        const licenses = await dataProvider.getList('CmsLicense', {
          filter: { [filterString]: { equalTo: data.id } },
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'createdDate', order: 'DESC' },
        })

        const today = new Date()
        const currentLicense = licenses.data.find((license) => {
          const licenseStart = new Date(license.licenseStart)
          const licenseEnd = new Date(license.licenseEnd)
          return licenseStart < today && today < licenseEnd
        })

        if (currentLicense) setLicenseData(currentLicense)
      }

      fetchLicenseData()
    }
  }, [data, resource, dataProvider])

  const tooltipText =
    'Notice that the availability is only recalculated every 10 minutes'

  // Showing Online/Offline-Status
  const renderStatusButton = () => {
    const isActive = data?.isActive ?? false
    // In case season/sery we fallback to isActive field from DB, since these objects dont hold licenses and videosources
    if (resource === 'CmsSery' || resource === 'CmsSeason') {
      return (
        <Tooltip title={tooltipText}>
          <Button
            variant="text"
            style={{
              color: isActive
                ? theme.palette.success.main
                : theme.palette.grey[500],
            }}
            startIcon={isActive ? <CheckIcon /> : <CloseIcon />}
            disabled={!isActive}
            className={classes.statusButton}
          >
            {isActive ? 'Online' : 'Offline'}
          </Button>
        </Tooltip>
      )
    }

    const validLicense = licenseData?.isActive ?? false
    const missingSourceContent = !data?.isActive && validLicense
    const missingSourceContentStatus = `Offline${
      isMediumScreenOrSmaller ? '' : ' (Missing Image or Video Source)'
    }`

    // License Data exists, check if its force offline or if image/video-sources are missing
    if (licenseData) {
      const isForceOffline = licenseData.forceOffline === true
      return (
        <Tooltip title={tooltipText}>
          <Button
            variant="text"
            style={{
              color:
                isForceOffline || missingSourceContent
                  ? theme.palette.grey[500]
                  : theme.palette.success.main,
            }}
            startIcon={
              isForceOffline || missingSourceContent ? (
                <CloseIcon />
              ) : (
                <CheckIcon />
              )
            }
            disabled={isForceOffline || missingSourceContent}
            className={classes.statusButton}
          >
            {isForceOffline
              ? 'Offline'
              : missingSourceContent
              ? missingSourceContentStatus
              : 'Online'}
          </Button>
        </Tooltip>
      )
    }

    // No License data exist, check if image/video-sources are missing
    return (
      <Tooltip title={tooltipText}>
        <Button
          variant="text"
          style={{
            color: theme.palette.grey[500],
          }}
          startIcon={<CloseIcon />}
          disabled
          className={classes.statusButton}
        >
          {missingSourceContent
            ? missingSourceContentStatus
            : 'No Valid License'}
        </Button>
      </Tooltip>
    )
  }

  // Enable/Disable License
  const renderAvailabilityButton = () => {
    const handleUpdateLicense = async (forceOffline: boolean) => {
      try {
        await dataProvider.update('CmsLicense', {
          id: licenseData.id,
          data: { ...licenseData, forceOffline },
          previousData: { ...licenseData },
        })

        notify(
          `The object was successfully ${
            forceOffline
              ? 'taken offline.  To take it online again, use the "Force Offline" switch in the license object.'
              : 'brought online'
          }.`,
          'success'
        )
      } catch (error) {
        console.error(error)
        notify(
          `The object could not be ${
            forceOffline ? 'taken offline' : 'brought online'
          }. If this problem persists, please contact support.`,
          'error'
        )
      }
      refresh()
    }

    if (licenseData?.forceOffline === false) {
      return (
        <>
          <Tooltip title={tooltipText}>
            <Button
              className={movieActionClasses.actionButton}
              startIcon={<BlockRoundedIcon />}
              onClick={() => handleUpdateLicense(true)}
            >
              {/* Check for isSmallScreen explicitly, as using only isMediumScreen would still render text for medium screens up to a certain breakpoint, 
              even when isSmallScreen is true. */}
              {isSmallScreen || !isMediumScreen ? 'Force Offline' : 'Offline'}
            </Button>
          </Tooltip>
        </>
      )
    } else if (licenseData?.forceOffline === true) {
      return (
        <>
          <Tooltip title="Bring this object online">
            <Button
              className={movieActionClasses.actionButton}
              startIcon={<ArrowUpwardIcon />}
              onClick={() => handleUpdateLicense(false)}
            >
              Activate
            </Button>
          </Tooltip>
        </>
      )
    }

    return null
  }

  const renderActionButtons = () => {
    return (
      <>
        {/* Toggle the availability of the license (online/offline). 
            Disabled for Universum as they currently publish to multiple channels from the same CMS. TODO: Add Universum support. */}
        {!isUniversumCustomer ? renderAvailabilityButton() : null}

        {/* Navigate to the previous episode: 
            Moves to the previous episode in the same season or the last episode of the previous season, if available. */}
        {resource === 'CmsEpisode' && data?.previousEpisodeId ? (
          <PreviousEpisodeButton {...props} />
        ) : null}

        {/* Navigate to the next episode: 
            Moves to the next episode in the same season or the first episode of the next season, if available. */}
        {resource === 'CmsEpisode' && data?.nextEpisodeId ? (
          <NextEpisodeButton {...props} />
        ) : null}

        {/* Download metadata XML file: 
            Available only for ARD customers and required for movies or seasons. */}
        {isArdCustomer &&
        (resource === 'CmsSeason' || resource === 'CmsMovie') ? (
          <DownloadMetadataXmlButton {...props} />
        ) : null}
      </>
    )
  }

  return (
    <Box
      style={{
        display: 'flex',
        padding: 0, // Remove any extra padding in order to properly allign items
        gap: theme.spacing(1),
      }}
    >
      {/* Status Section */}
      {/* Disabled for Universum as they currently publish to multiple channels from the same CMS. TODO: Add Universum support. */}
      {!isUniversumCustomer ? renderStatusButton() : null}

      {/* Actions Section - Turning into collapsible menu for small screens */}
      {isSmallScreen ? (
        <>
          <IconButton color="primary" onClick={() => toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="bottom"
            open={isDrawerOpen}
            onClose={() => toggleDrawer(false)}
            PaperProps={{
              style: {
                borderTopLeftRadius: theme.spacing(2),
                borderTopRightRadius: theme.spacing(2),
              },
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              position="relative" // Allows absolute positioning of the close button
              style={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                padding: theme.spacing(2),
                borderTopLeftRadius: theme.spacing(2),
                borderTopRightRadius: theme.spacing(2),
              }}
            >
              <Typography variant="h6">Actions</Typography>
              <IconButton
                style={{
                  position: 'absolute',
                  right: theme.spacing(2),
                  color: theme.palette.common.white,
                }}
                onClick={() => toggleDrawer(false)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
            <Box
              display="flex"
              flexDirection="column"
              style={{
                gap: theme.spacing(1),
                padding: theme.spacing(2),
              }}
            >
              {renderActionButtons()}
            </Box>
          </Drawer>
        </>
      ) : (
        // Inline Actions for Larger Screen
        <Box
          className={classes.action}
          style={{
            gap: theme.spacing(1),
          }}
        >
          {renderActionButtons()}
        </Box>
      )}
    </Box>
  )
}

export const MovieAction = (props: CustomActionProps) => {
  return <DisplayAvailabilityAndActions {...props} />
}
