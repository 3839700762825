import { Button, CircularProgress, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import EditIcon from '@material-ui/icons/Edit'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import TouchAppIcon from '@material-ui/icons/TouchApp'
import React, { Fragment, useEffect, useState } from 'react'
import {
  BulkActionProps,
  BulkDeleteButton,
  Record,
  useGetIdentity,
  useListContext,
  usePermissions,
  useUnselectAll,
} from 'react-admin'
import { OnTablePermissions, Permissions } from '../authProvider'
import useXmlDownload from '../hooks/useXmlDownload'
import {
  TABLE_ENTRY_DELETE_RESTRICTION_ROLES,
  isArdCustomer,
} from '../lib/config'

const useStyles = makeStyles(() => ({
  button: {
    marginRight: 5,
  },
}))

export function BulkResourceActions(
  props: BulkActionProps & {
    setFunctionEntries?: React.Dispatch<React.SetStateAction<Record[] | null>>
    openedModal?: Function
    selectedData: Record[]
    setSelectedData: React.Dispatch<React.SetStateAction<Record[]>>
    setBatchEditOpen: React.Dispatch<React.SetStateAction<boolean>>
    setBatchCreateLicenseOpen: React.Dispatch<React.SetStateAction<boolean>>
  }
) {
  const classes = useStyles()
  const {
    basePath,
    openedModal,
    resource,
    selectedIds,
    setFunctionEntries,
    selectedData,
    setSelectedData,
    setBatchEditOpen,
    setBatchCreateLicenseOpen,
  } = props
  const { data } = useListContext()
  const dataArray = Object.keys(data).map((id) => data[id])
  const unselectAll = useUnselectAll(resource)

  const [resPermissions, setResPermissions] =
    useState<OnTablePermissions>(undefined)

  const [deleteAllowed, setDeleteAllowed] = useState<boolean>(true)

  const { permissions, loaded: permissionLoaded } =
    usePermissions<Permissions>()
  const { identity, loaded: identityLoaded } = useGetIdentity()

  const { downloadXmls, loading: isDownloading } = useXmlDownload()
  const MAX_DOWNLOAD_LIMIT = 50

  useEffect(() => {
    if (permissions) {
      setResPermissions(permissions.tablePermissions[resource])
    }
  }, [permissionLoaded])

  useEffect(() => {
    if (identity && selectedData.length > 0) {
      const deleteRestriction = (
        JSON.parse(TABLE_ENTRY_DELETE_RESTRICTION_ROLES) as [
          {
            tableName: string
            restrictedRoles: string[]
            restrictions: { [fieldName in string]: string }
          }
        ]
      ).find(
        (restriction) =>
          restriction.tableName === resource &&
          restriction.restrictedRoles.includes(identity.role)
      )
      if (deleteRestriction) {
        for (const field in deleteRestriction.restrictions) {
          const retrictedValue = deleteRestriction.restrictions[field]
          const hasForbiddenValues = selectedData.some(
            (d) => d.role !== retrictedValue
          )
          if (hasForbiddenValues) {
            setDeleteAllowed(false)
          } else {
            setDeleteAllowed(true)
          }
        }
      }
    }
  }, [identityLoaded, selectedData])

  useEffect(() => {
    if (selectedIds) {
      const allData = [...dataArray, ...selectedData]
      // @ts-ignore
      const newSelectedData: Record[] = selectedIds
        .map((id) => allData.find((data) => data.id === id))
        .filter((data) => data !== undefined)

      if (newSelectedData) {
        setSelectedData(newSelectedData)
      } else {
        setSelectedData([])
      }
    }
  }, [selectedIds])

  const handleSelectButton = () => {
    if (openedModal && setFunctionEntries && selectedData.length > 0) {
      console.log(selectedData)
      setFunctionEntries(selectedData)
      unselectAll() // unselect
      openedModal(false) // close modal
    }
  }

  return (
    <Fragment>
      {setFunctionEntries ? (
        <Button
          startIcon={<TouchAppIcon />}
          variant="text"
          onClick={handleSelectButton}
          size="small"
          color="primary"
          className={classes.button}
        >
          Select Checked
        </Button>
      ) : null}

      {isArdCustomer &&
      (props.resource === 'CmsMovie' ||
        props.resource === 'CmsSery' ||
        props.resource === 'CmsSeason' ||
        props.resource === 'CmsEpisode' ||
        props.resource === 'CmsFeedObject') &&
      permissions &&
      permissions.tablePermissions['CmsLicense'] &&
      setBatchCreateLicenseOpen ? (
        <Tooltip
          title={
            'License will be applied to all selected entries. For Series and Seasons, license will be applied for all connected Episodes.'
          }
        >
          <span>
            <Button
              startIcon={<LibraryBooksIcon />}
              variant="text"
              onClick={() => setBatchCreateLicenseOpen(true)}
              size="small"
              color="primary"
              className={classes.button}
            >
              Batch License
            </Button>
          </span>
        </Tooltip>
      ) : null}

      {isArdCustomer &&
      (props.resource === 'CmsMovie' || props.resource === 'CmsSeason') ? (
        <Tooltip
          title={
            selectedData.length > MAX_DOWNLOAD_LIMIT
              ? `Too many items selected. Please select ${MAX_DOWNLOAD_LIMIT} or fewer items.`
              : ''
          }
        >
          <span>
            <Button
              startIcon={
                isDownloading ? (
                  <CircularProgress size={15} />
                ) : (
                  <DownloadIcon />
                )
              }
              variant="text"
              onClick={() => {
                downloadXmls(
                  selectedData.map((data) => {
                    return {
                      externalIdentifier: data.externalIdentifier,
                      __typename: props.resource,
                    }
                  })
                )
              }}
              size="small"
              color="primary"
              className={classes.button}
              disabled={
                isDownloading || selectedData.length > MAX_DOWNLOAD_LIMIT
              }
            >
              XML
            </Button>
          </span>
        </Tooltip>
      ) : null}

      <Button
        startIcon={<CheckBoxOutlineBlankIcon />}
        variant="text"
        onClick={() => {
          unselectAll()
        }}
        size="small"
        color="primary"
        className={classes.button}
      >
        Unselect All
      </Button>

      {!openedModal && resPermissions && resPermissions.canUpdate ? (
        <Button
          startIcon={<EditIcon />}
          variant="text"
          onClick={() => setBatchEditOpen(true)}
          size="small"
          color="primary"
          className={classes.button}
        >
          Batch Edit
        </Button>
      ) : null}

      {/* default bulk delete action */}
      {resPermissions && resPermissions.canDelete && deleteAllowed ? (
        <BulkDeleteButton basePath={basePath} />
      ) : undefined}
    </Fragment>
  )
}
