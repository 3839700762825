// SECURITY NOTICE: entries here are exposed in the client!

// also add in frontendEnvironment of server.js to expose to frontend

declare global {
  interface Window {
    FRONTEND_ENVIRONMENT: {
      [key: string]: string | null | undefined
    }
  }
}

console.log('current version is:', process.env.REACT_APP_GIT_SHA)
console.log('current version is from:', process.env.REACT_APP_GIT_DATE)

// process.env has to be the full path, as webpack will match and replace the string!
export const NODE_ENV =
  window.FRONTEND_ENVIRONMENT.NODE_ENV || process.env.NODE_ENV || 'development'

export const SMPL_ENV =
  window.FRONTEND_ENVIRONMENT.SMPL_ENV || process.env.SMPL_ENV || 'dev'
//   BASE_URL: null,

// change via a .env variable!
export const SMPL_CUSTOMER =
  window.FRONTEND_ENVIRONMENT.SMPL_CUSTOMER ||
  process.env.REACT_APP_SMPL_CUSTOMER ||
  'universum-dev'

if (!SMPL_CUSTOMER) {
  throw new Error('please supply SMPL_CUSTOMER/REACT_APP_SMPL_CUSTOMER')
}

export type SUPPORTED_CUSTOMERS =
  | 'netzkino'
  | 'kixi'
  | 'universum'
  | 'filmhome'
  | 'ard'
  | 'flexgold'
  | 'florianhomm'

export const CUSTOMER = SMPL_CUSTOMER.replace(
  /-\w+$/,
  ''
) as SUPPORTED_CUSTOMERS

export const isNetzkinoCustomer = SMPL_CUSTOMER.startsWith('netzkino')
export const isKixiCustomer = SMPL_CUSTOMER.startsWith('kixi')
export const isUniversumCustomer = SMPL_CUSTOMER.startsWith('universum')
export const isFilmhomeCustomer = SMPL_CUSTOMER.startsWith('filmhome')
export const isArdCustomer = SMPL_CUSTOMER.startsWith('ard')
export const isFlexgoldCustomer = SMPL_CUSTOMER.startsWith('flexgold')
export const isFlorianhommCustomer = SMPL_CUSTOMER.startsWith('florianhomm')

export const VIDEOPLAYER_TOKEN_ACCESS_REQUIRED = isArdCustomer

export const DASHBOARD_ACCESS_ALLOWED_ROLES =
  window.FRONTEND_ENVIRONMENT.DASHBOARD_ACCESS_ALLOWED_ROLES ||
  process.env.REACT_APP_DASHBOARD_ACCESS_ALLOWED_ROLES ||
  JSON.stringify(['admin'])

// because we have to give more permission then neccessary on some tables to some roles for them to access the FE
// we disable the rendering for these tables, here instead
// TODO: alternativly we can also track what to render on an extra DB table, future task?
export const DASHBOARD_TABLES_DISABLE_RENDER_BY_ROLE =
  window.FRONTEND_ENVIRONMENT.DASHBOARD_TABLES_DISABLE_RENDER_BY_ROLES ||
  process.env.REACT_APP_DASHBOARD_TABLES_DISABLE_RENDER_BY_ROLE ||
  JSON.stringify([
    {
      role: 'editorial',
      tableNames: [
        'SubscriptionProduct',
        'CmsTag',
        'PaymentScheduledCancellation',
        'PaymentExternalUser',
        'SubscriptionTerm',
        'Subscription',
        'User',
        'WatchlistItem',
        'WishlistItem',
      ],
    },
    {
      role: 'content-manager',
      tableNames: [
        'SubscriptionProduct',
        'CmsTag',
        'PaymentScheduledCancellation',
        'PaymentExternalUser',
        'SubscriptionTerm',
        'Subscription',
        'User',
        'WatchlistItem',
        'WishlistItem',
      ],
    },
    {
      role: 'reporting',
      tableNames: [
        'CmsCategory',
        'CmsMovieContentCategory',
        'CmsPerson',
        'CmsMovieContentPerson',
        'CmsClip',
        'CmsImage',
        'CmsTag',
        'User',
        'WatchlistItem',
        'WishlistItem',
      ],
    },
    {
      role: 'customer-support',
      tableNames: [
        'CmsCategory',
        'CmsMovieContentCategory',
        'CmsPerson',
        'CmsMovieContentPerson',
        'CmsClip',
        'CmsImage',
        'CmsTag',
        'WatchlistItem',
        'WishlistItem',
      ],
    },
    {
      role: 'quality-control',
      tableNames: [
        'CmsCategory',
        'CmsMovieContentCategory',
        'CmsTag',
        'SubscriptionProduct',
        'PaymentScheduledCancellation',
        'PaymentExternalUser',
        'SubscriptionTerm',
        'Subscription',
        'User',
        'WatchlistItem',
        'WishlistItem',
      ],
    },
  ])

export const TABLE_ENTRY_DELETE_RESTRICTION_ROLES =
  window.FRONTEND_ENVIRONMENT.TABLE_ENTRY_DELETE_RESTRICTION_ROLES ||
  process.env.REACT_APP_TABLE_ENTRY_DELETE_RESTRICTION_ROLES ||
  JSON.stringify(
    // ARD-888
    [
      {
        tableName: 'User',
        restrictedRoles: ['customer-support'],
        // only allow to delete if entry HAS this key-value pair, else not allowed
        restrictions: {
          role: 'user',
        },
      },
    ]
  )

export const REPORTING_TYPE_ALLOWED_ROLES =
  window.FRONTEND_ENVIRONMENT.REPORTING_TYPE_ALLOWED_ROLES ||
  process.env.REACT_APP_REPORTING_TYPE_ALLOWED_ROLES ||
  JSON.stringify(
    // ARD-888
    // for reportType see REPORT_TYPE in licenseReport/index.ts
    [
      {
        reportType: 'license',
        allowedRoles: ['admin', 'editorial', 'content-manager', 'reporting'],
        // this is the default, all vod customer should have access to this
        accessibleForCustomers: [
          'ard',
          'netzkino',
          'kixi',
          'universum',
          'filmhome',
        ],
      },
      {
        reportType: 'user',
        allowedRoles: ['admin', 'reporting'],
        accessibleForCustomers: ['ard', 'filmhome'],
      },
      {
        reportType: 'content',
        allowedRoles: ['admin', 'editorial', 'content-manager', 'reporting'],
        accessibleForCustomers: ['ard', 'universum', 'filmhome'],
      },
      {
        reportType: 'subscription',
        allowedRoles: ['admin', 'editorial', 'reporting'],
        accessibleForCustomers: ['ard', 'filmhome'],
      },
      {
        reportType: 'dailySubscription',
        allowedRoles: ['admin', 'reporting'],
        accessibleForCustomers: ['ard'],
      },
      {
        reportType: 'marketing',
        allowedRoles: ['admin', 'editorial', 'reporting'],
        accessibleForCustomers: ['ard'],
      },
      {
        reportType: 'watchlistAnomaly',
        allowedRoles: ['admin'],
        accessibleForCustomers: ['ard'],
      },
      {
        reportType: 'partnerSource',
        allowedRoles: ['admin', 'reporting'],
        accessibleForCustomers: ['ard'],
      },
    ]
  )

// on the following table we don't render the export button for certain role
// assuming the role can see the table
export const BLACKLIST_TABLE_EXPORT_BY_ROLE =
  window.FRONTEND_ENVIRONMENT.BLACKLIST_TABLE_EXPORT_BY_ROLE ||
  process.env.REACT_APP_BLACKLIST_TABLE_EXPORT_BY_ROLE ||
  JSON.stringify(
    // ARD-888
    [
      {
        tableName: 'User',
        blackListedRole: ['customer-support'],
      },
    ]
  )

// this is a restrictive permission, e.g. if a dashboardLabel is not mentioned here then it will be blocked in general
export const GRAFANA_DASHBOARD_ACCESS_BY_ROLE =
  window.FRONTEND_ENVIRONMENT.GRAFANA_DASHBOARD_ACCESS_BY_ROLE ||
  process.env.REACT_APP_GRAFANA_DASHBOARD_ACCESS_BY_ROLE ||
  JSON.stringify({
    'CEO Dashboard': ['admin'],
    Übersicht: ['admin', 'content-manager'],
  })

export const SMPL_DOMAIN =
  window.FRONTEND_ENVIRONMENT.SMPL_DOMAIN ||
  process.env.REACT_APP_SMPL_DOMAIN ||
  'universum.dev.smpl.services'

export const SMPL_USER_AUTH_API_URL = (
  window.FRONTEND_ENVIRONMENT.SMPL_USER_AUTH_API_URL ||
  process.env.REACT_APP_SMPL_USER_AUTH_API_URL ||
  `https://auth.${SMPL_DOMAIN}`
).replace('http://', 'https://')

export const SMPL_PUBLIC_API_URL =
  window.FRONTEND_ENVIRONMENT.SMPL_PUBLIC_API_URL ||
  process.env.REACT_APP_SMPL_PUBLIC_API_URL ||
  `https://public.${SMPL_DOMAIN}`

export const SMPL_DATA_API_URL =
  window.FRONTEND_ENVIRONMENT.SMPL_DATA_API_URL ||
  process.env.REACT_APP_SMPL_DATA_API_URL ||
  `https://data.${SMPL_DOMAIN}/${SMPL_CUSTOMER}/graphql`
//   SMPL_DOMAIN: null,
//   CUSTOMER: null,
// EXPORT_LIMIT: '1000',

export const VIDEO_HOST_ENDPOINT =
  window.FRONTEND_ENVIRONMENT.VIDEO_HOST_ENDPOINT ||
  process.env.REACT_APP_VIDEO_HOST_ENDPOINT ||
  'https://d1anlvnx6nahjk.cloudfront.net/'

export const PAYMENT_ENDPOINT =
  window.FRONTEND_ENVIRONMENT.PAYMENT_ENDPOINT ||
  process.env.REACT_APP_PAYMENT_ENDPOINT ||
  `https://payment.${SMPL_DOMAIN}`

export const PAYMENT_ENDPOINT_API =
  window.FRONTEND_ENVIRONMENT.PAYMENT_ENDPOINT ||
  process.env.REACT_APP_PAYMENT_ENDPOINT ||
  `https://payment.${SMPL_DOMAIN}`

export const EXTERNAL_LICENSE_REPORTING_ENDPOINT =
  window.FRONTEND_ENVIRONMENT.EXTERNAL_LICENSE_REPORTING_ENDPOINT ||
  process.env.REACT_APP_EXTERNAL_LICENSE_REPORTING_ENDPOINT ||
  `https://license-reporting.${SMPL_DOMAIN}`

export const TASK_SCHEDULER_ENDPOINT =
  window.FRONTEND_ENVIRONMENT.TASK_SCHEDULER_ENDPOINT ||
  process.env.REACT_APP_TASK_SCHEDULER_ENDPOINT ||
  `https://task-scheduler.${SMPL_DOMAIN}`

export const PAYMENT_API_MAPPING =
  window.FRONTEND_ENVIRONMENT.PAYMENT_API_MAPPING ||
  process.env.REACT_APP_PAYMENT_API_MAPPING ||
  ''

export const VIDEOPLAYER_WIDEWINE_LICENSE_SERVER =
  window.FRONTEND_ENVIRONMENT.VIDEOPLAYER_WIDEWINE_LICENSE_SERVER ||
  process.env.REACT_APP_VIDEOPLAYER_WIDEWINE_LICENSE_SERVER ||
  'https://token.universum.dev.smpl.services/token/wv/'

export const VIDEOPLAYER_FAIRPLAY_LICENSE_SERVER =
  window.FRONTEND_ENVIRONMENT.VIDEOPLAYER_FAIRPLAY_LICENSE_SERVER ||
  process.env.REACT_APP_VIDEOPLAYER_FAIRPLAY_LICENSE_SERVER ||
  'https://token.universum.dev.smpl.services/token/fp/'

export const VIDEOPLAYER_PLAYREADY_LICENSE_SERVER =
  window.FRONTEND_ENVIRONMENT.VIDEOPLAYER_PLAYREADY_LICENSE_SERVER ||
  process.env.REACT_APP_VIDEOPLAYER_PLAYREADY_LICENSE_SERVER ||
  'https://token.universum.dev.smpl.services/token/pr/'

export const VIDEOPLAYER_FAIRPLAY_CERTIFICATE_URL =
  window.FRONTEND_ENVIRONMENT.VIDEOPLAYER_FAIRPLAY_CERTIFICATE_URL ||
  process.env.REACT_APP_VIDEOPLAYER_FAIRPLAY_CERTIFICATE_URL ||
  // 'https://universum-dev-public.s3-eu-west-1.amazonaws.com/fairplay.cer'
  'https://token.universum.dev.smpl.services/token/fp/fairplay.cer'

export const VIDEOPLAYER_WIDEWINE_LICENSE_HEADER =
  window.FRONTEND_ENVIRONMENT.VIDEOPLAYER_WIDEWINE_LICENSE_HEADER ||
  process.env.REACT_APP_VIDEOPLAYER_WIDEWINE_LICENSE_HEADER ||
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2ZXJzaW9uIjoxLCJjb21fa2V5X2lkIjoiNjllNTQwODgtZTllMC00NTMwLThjMWEtMWViNmRjZDBkMTRlIiwibWVzc2FnZSI6eyJ0eXBlIjoiZW50aXRsZW1lbnRfbWVzc2FnZSIsImtleXMiOlt7ImlkIjoiYjM3ODg0MjAtNjRiYy00YzQ5LThjYmQtZmE2NGY0MTk4ZDdjIn1dfX0.zpUjmrA7XUgG9aRJK3nSaWe5AvtXwfCyqyPEVR_JdFs'

export const MEDIA_CONVERT_CONTROLLER_API =
  window.FRONTEND_ENVIRONMENT.MEDIA_CONVERT_CONTROLLER_API ||
  process.env.MEDIA_CONVERT_CONTROLLER_API ||
  'https://mediaconvert-controller.universum.dev.smpl.services'

export const EXTERNAL_SMPL_TOKEN_API =
  window.FRONTEND_ENVIRONMENT.EXTERNAL_SMPL_TOKEN_API ||
  process.env.EXTERNAL_SMPL_TOKEN_API ||
  process.env.REACT_APP_EXTERNAL_SMPL_TOKEN_API ||
  'https://token.universum.dev.smpl.services'

export const GRAFANA_DASHBOARD_URLS =
  window.FRONTEND_ENVIRONMENT.GRAFANA_DASHBOARD_URLS ||
  process.env.REACT_APP_GRAFANA_DASHBOARD_URLS ||
  process.env.GRAFANA_DASHBOARD_URLS

export const SMPL_GRAFANA_PATH =
  window.FRONTEND_ENVIRONMENT.SMPL_GRAFANA_PATH ||
  process.env.SMPL_GRAFANA_PATH ||
  '/grafana/login'

export const IMG_CLOUDFRONT_URL_PREFIX =
  window.FRONTEND_ENVIRONMENT.IMG_CLOUDFRONT_URL_PREFIX ||
  process.env.IMG_CLOUDFRONT_URL_PREFIX ||
  process.env.REACT_APP_IMG_CLOUDFRONT_URL_PREFIX

export const MOVIE_PREFIX =
  window.FRONTEND_ENVIRONMENT.MOVIE_PREFIX ||
  process.env.MOVIE_PREFIX ||
  process.env.REACT_APP_MOVIE_PREFIX ||
  'a0S'

export const OMDB_API_ENDPOINT =
  window.FRONTEND_ENVIRONMENT.OMDB_API_ENDPOINT ||
  process.env.OMDB_API_ENDPOINT ||
  process.env.REACT_APP_OMDB_API_ENDPOINT ||
  ''

export const ADMIN_ROLE_SELECTION = window.FRONTEND_ENVIRONMENT
  .ADMIN_ROLE_SELECTION ||
  process.env.ADMIN_ROLE_SELECTION ||
  process.env.REACT_APP_ADMIN_ROLE_SELECTION || [
    { role: 'admin', label: 'Full Admin Access' },
    { role: 'user', label: 'User' },
  ]

/**
 * flexgold only env
 */
export const EXTERNAL_API_ORDER_ENDPOINT =
  window.FRONTEND_ENVIRONMENT.EXTERNAL_API_ORDER_ENDPOINT ||
  process.env.EXTERNAL_API_ORDER_ENDPOINT ||
  process.env.REACT_APP_EXTERNAL_API_ORDER_ENDPOINT ||
  'https://orders.flexgold.smpl.dev/'
/**
 * flexgold only env
 */
export const SMPL_FLEXGOLD_API_BASEURL: string | undefined =
  window.FRONTEND_ENVIRONMENT.SMPL_FLEXGOLD_API_BASEURL ||
  process.env.SMPL_FLEXGOLD_API_BASEURL ||
  process.env.REACT_APP_SMPL_FLEXGOLD_API_BASEURL

export const PLATFORM_OPTIONS = window.FRONTEND_ENVIRONMENT.PLATFORM_OPTIONS ||
  process.env.PLATFORM_OPTIONS ||
  process.env.REACT_APP_PLATFORM_OPTIONS || {
    data: [{ label: 'none', value: null }],
  }

export const IS_NETZKINO_LIKE = /(netzkino|loveisawonder|kixi)/.test(
  SMPL_DATA_API_URL
)

export const PMD_VIDEO_URL_PREFIX =
  window.FRONTEND_ENVIRONMENT.PMD_VIDEO_URL_PREFIX ||
  process.env.PMD_VIDEO_URL_PREFIX ||
  process.env.REACT_APP_PMD_VIDEO_URL_PREFIX ||
  ''

export const HLS_VIDEO_URL_PREFIX =
  window.FRONTEND_ENVIRONMENT.HLS_VIDEO_URL_PREFIX ||
  process.env.HLS_VIDEO_URL_PREFIX ||
  process.env.REACT_APP_HLS_VIDEO_URL_PREFIX ||
  ''

export const GOOGLE_DEV_ID =
  window.FRONTEND_ENVIRONMENT.GOOGLE_DEV_ID ||
  process.env.GOOGLE_DEV_ID ||
  process.env.REACT_APP_GOOGLE_DEV_ID

export const PREVIEW_IMAGE_WIDTH =
  window.FRONTEND_ENVIRONMENT.PREVIEW_IMAGE_WIDTH ||
  process.env.PREVIEW_IMAGE_WIDTH ||
  process.env.REACT_APP_PREVIEW_IMAGE_WIDTH ||
  300

export const PREVIEW_IMAGE_HEIGHT =
  window.FRONTEND_ENVIRONMENT.PREVIEW_IMAGE_HEIGHT ||
  process.env.PREVIEW_IMAGE_HEIGHT ||
  process.env.REACT_APP_PREVIEW_IMAGE_HEIGHT ||
  200

// Transcoding Job Priority Management Variables
export const HIGH_TRANSCODINGJOB_PRIORITY = 45 // if changed, change var in mediaconvert controller as well
export const REGULAR_TRANSCODINGJOB_PRIORITY = 40 // if changed, change var in mediaconvert controller as well
export const LOW_TRANSCODINGJOB_PRIORITY = 30 // if changed, change var in mediaconvert controller as well
export const RESERVED_TRANSCODING_QUEUE_ARN =
  window.FRONTEND_ENVIRONMENT.RESERVED_TRANSCODING_QUEUE_ARN ||
  process.env.REACT_APP_RESERVED_TRANSCODING_QUEUE_ARN ||
  process.env.RESERVED_TRANSCODING_QUEUE_ARN
export const DEFAULT_TRANSCODING_QUEUE_ARN =
  window.FRONTEND_ENVIRONMENT.DEFAULT_TRANSCODING_QUEUE_ARN ||
  process.env.REACT_APP_DEFAULT_TRANSCODING_QUEUE_ARN ||
  process.env.DEFAULT_TRANSCODING_QUEUE_ARN
