import {
  Card,
  CardContent,
  Chip,
  Fade,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { green, red } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import ClearIcon from '@material-ui/icons/Clear'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import SearchIcon from '@material-ui/icons/Search'
import { useCallback, useEffect, useState } from 'react'
import { Record, useDataProvider, useNotify } from 'react-admin'
import { useDropzone } from 'react-dropzone'
import { useField, useForm, useFormState } from 'react-final-form'
import { FieldTraverser } from '../../dataProvider/introspections/SchemaTraverser'
import { SMPL_TEMP_IMG_FILES } from '../GenericEditPage'
import {
  CustomFile,
  isValidImageFileName,
  replaceSpecialCharacters,
} from '../customFields/DropZone'
import { DataGridModal } from '../renderInput'

const useStyles = makeStyles({
  actionZone: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  container: {
    minHeight: 48,
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
  },
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '2px #eeeeee dashed',
    backgroundColor: '#fafafa',
  },
  title: {
    fontSize: 14,
  },
  card: {
    marginBottom: 10,
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
    marginBottom: 16,
    width: '100%',
    maxHeight: 200,
  },
  thumbAndButtonContainer: {
    width: '100%',
    maxHeight: 200,
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    padding: 4,
    boxSizing: 'border-box',
    width: '100%',
    maxHeight: 200,
    position: 'relative',
    top: -27,
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  img: {
    display: 'block',
    width: 'auto',
    maxHeight: 190,
  },
  imgToolbar: { display: 'flex', justifyContent: 'flex-end' },
  imgIconTopCorner: {
    height: '25px',
    width: '25px',
    border: '1px solid lightgrey',
    backgroundColor: 'white',
    borderRadius: '1px 1px 1px 1px',
    zIndex: 1000,
  },
})

type ImageInputProps = {
  field: FieldTraverser
  label?: string
  className: string
}

export type TempImg = {
  coverImageId?: File
  widescreenImageId?: File
  headerImageId?: File
  logoId?: File
}

export const ImageInput = (props: ImageInputProps) => {
  const { field, label, className: style } = props
  const {
    name: fieldName,
    resource: { name: resource },
  } = field
  const classes = useStyles()
  const formField = useField(fieldName)

  // TODO: query image and show it if the id in record is not empty
  const notify = useNotify()

  const form = useForm()
  const currentFieldState = useFormState()
  useField(
    SMPL_TEMP_IMG_FILES
    // don't do this or 'Maximum update depth exceeded' will happens
    //   {
    //   initialValue: [],
    // }
  )
  const [files, setFiles] = useState<Array<CustomFile>>([])

  const [image, setImage] = useState<Record | undefined>()
  const imgId: string | undefined = currentFieldState.values[fieldName]
  const dataProvider = useDataProvider()

  const referenceTypeName = 'CmsImage'
  const [selectionOpen, setSelectionOpen] = useState(false)
  const [selectedId, setSelectedId] = useState<string | undefined>()

  const openSelectionModal = () => {
    setSelectionOpen(true)
  }

  const closeSelectionModal = () => {
    setSelectionOpen(false)
  }

  useEffect(() => {
    const fetchImage = async (imgId: string) => {
      try {
        const imgRes = await dataProvider.getOne(referenceTypeName, {
          id: imgId,
        })

        if (imgRes.data) {
          setImage({
            ...imgRes.data,
          })
        } else {
          throw new Error(`Can not query exiting image with id ${imgId}.`)
        }
      } catch (error) {
        console.error(error)
      }
    }

    if (imgId) {
      fetchImage(imgId)
    }

    if (selectedId && selectedId !== imgId) {
      // write it to form
      // INFO this somehow did not update the form correctly:
      // form.change(fieldName, selectedId)
      // but this does
      formField.input.onChange(selectedId)

      // query to show the img
      fetchImage(selectedId)
      // remove uploaded files on select of an image in modal
      setFiles([])
    }
  }, [selectedId])

  const removeFromForm = () => {
    // remove from form
    // INFO this somehow did not update the form correctly:
    // form.change(fieldName, null)
    // but this does
    formField.input.onChange(null)
    // remove state to not render image anymore
    setImage(undefined)
  }

  const onDrop = useCallback(
    (acceptedFiles) => {
      const newFiles: CustomFile[] = acceptedFiles
        .map((file: File) => {
          const originalFileName = file.name
          const replacedFileName = replaceSpecialCharacters(originalFileName)

          if (!isValidImageFileName(replacedFileName)) {
            notify(
              `Invalid file name "${originalFileName}". Allowed characters: a-z, A-Z, 0-9, -, _, and a single . for extension.`,
              'warning'
            )
            return null
          }

          const fileWithReplacedName = new File([file], replacedFileName, {
            type: file.type,
          })
          const preview = URL.createObjectURL(fileWithReplacedName)
          return {
            preview: preview,
            file: fileWithReplacedName,
            state: 'waiting', // we don't care for that here only for delete button
          }
        })
        .filter(Boolean) as CustomFile[]

      if (files.length === 1) {
        URL.revokeObjectURL(files[0].preview) // revoke to avoid memleak
      }
      setFiles(newFiles)
      removeFromForm()
    },
    [files]
  )

  useEffect(() => {
    if (files.length > 0) {
      const tempImgFiles: TempImg = {
        ...currentFieldState.values[SMPL_TEMP_IMG_FILES],
        [fieldName]: files[0].file,
      }
      form.change(SMPL_TEMP_IMG_FILES, tempImgFiles)
    }
  }, [files])

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/jpg', 'image/jpeg', 'image/png'],
    onDrop,
    multiple: false,
    maxSize: 5.7 * 1024 ** 2, // 5.7MiB
    onDropRejected: (rejectedFiles) => {
      notify(
        'Some files were rejected. Please make sure the file is an image and does not exceed 5.7MiB.',
        'error'
      )
    },
  })

  const removeFromFiles = (cFile: CustomFile) => {
    URL.revokeObjectURL(cFile.preview) // revoke to avoid memleak
    setFiles([])
    const tempImgFiles: TempImg = {
      ...currentFieldState.values[SMPL_TEMP_IMG_FILES],
      [fieldName]: undefined,
    }
    form.change(SMPL_TEMP_IMG_FILES, tempImgFiles)
  }

  return (
    <div className={style} key={fieldName}>
      <Card className={classes.card}>
        <CardContent style={{ padding: 16 }}>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            {label}
          </Typography>
          <div className={classes.actionZone}>
            <section className={classes.container}>
              <div {...getRootProps({ className: classes.dropzone })}>
                <input {...getInputProps()} />
                <Typography variant="h4" color="textSecondary">
                  +
                </Typography>
              </div>
            </section>
            <section>
              <Chip
                label={'Select'}
                variant="outlined"
                icon={<SearchIcon />}
                onClick={() => {
                  openSelectionModal()
                }}
              />
            </section>
          </div>
          {image && !(files && files.length > 0) ? (
            <div className={classes.thumbsContainer}>
              <ThumbNail image={image} removeFromForm={removeFromForm} />
            </div>
          ) : null}
          {files && files.length > 0
            ? files.map((file: CustomFile) => {
                return (
                  <div className={classes.thumbsContainer} key={file.file.name}>
                    <ThumbNail
                      customFile={file}
                      removeFromFiles={removeFromFiles}
                    />
                  </div>
                )
              })
            : null}
        </CardContent>
      </Card>

      <DataGridModal
        open={selectionOpen}
        onClose={closeSelectionModal}
        // onOpenCreateModal={openCreateModal}
        resource={referenceTypeName}
        basePath={`/${referenceTypeName}`}
        location={{
          hash: '',
          pathname: `/${referenceTypeName}`,
          search: '',
          state: null,
        }}
        calledFromModal={true}
        setFunction={setSelectedId}
        openedModal={setSelectionOpen}
        hasCreateInModal={false}
        fieldName={field.name}
      />
    </div>
  )
}

type ThumbNailProps = {
  // upload:
  customFile?: CustomFile
  removeFromFiles?: Function
  // exist or selected
  image?: Record
  removeFromForm?: Function
}

const ThumbNail = (props: ThumbNailProps) => {
  const classes = useStyles()
  const { customFile, image, removeFromFiles, removeFromForm } = props

  const handleClickOpen = (url: string) => {
    var win = window.open(url, '_blank')
    if (win) {
      win.focus()
    }
  }

  if (customFile && removeFromFiles) {
    const { state, file, id, errorMessage, preview } = customFile
    const { name } = file

    return (
      <div className={classes.thumbAndButtonContainer}>
        <div className={classes.imgToolbar}>
          <div className={classes.imgIconTopCorner}>
            <Tooltip
              title="Open in new tab"
              placement="top"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 500 }}
              enterDelay={100}
            >
              <OpenInNewIcon
                style={{ color: green[500], cursor: 'pointer' }}
                onClick={() => {
                  handleClickOpen(preview)
                }}
              />
            </Tooltip>
          </div>
          <div className={classes.imgIconTopCorner}>
            <Tooltip
              title="Delete"
              placement="top"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 500 }}
              enterDelay={100}
            >
              <ClearIcon
                style={{ color: red[500], cursor: 'pointer' }}
                onClick={() => {
                  removeFromFiles(customFile)
                }}
              />
            </Tooltip>
          </div>
        </div>
        <div className={classes.thumb} key={name}>
          <div className={classes.thumbInner}>
            <Tooltip
              title={name}
              placement="bottom"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 500 }}
              enterDelay={100}
            >
              <img src={preview} className={classes.img} alt={name} />
            </Tooltip>
          </div>
        </div>
      </div>
    )
  }
  if (image && removeFromForm) {
    const { masterUrl, name } = image
    return (
      <div className={classes.thumbAndButtonContainer}>
        <div className={classes.imgToolbar}>
          <div className={classes.imgIconTopCorner}>
            <Tooltip
              title="Open in new tab"
              placement="top"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 500 }}
              enterDelay={100}
            >
              <OpenInNewIcon
                style={{ color: green[500], cursor: 'pointer' }}
                onClick={() => {
                  handleClickOpen(masterUrl)
                }}
              />
            </Tooltip>
          </div>
          <div className={classes.imgIconTopCorner}>
            <Tooltip
              title="Delete"
              placement="top"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 500 }}
              enterDelay={100}
            >
              <ClearIcon
                style={{ color: red[500], cursor: 'pointer' }}
                onClick={() => {
                  removeFromForm()
                }}
              />
            </Tooltip>
          </div>
        </div>

        <div className={classes.thumb}>
          <div className={classes.thumbInner}>
            <Tooltip
              title={name}
              placement="bottom"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 500 }}
              enterDelay={100}
            >
              <img src={masterUrl} className={classes.img} alt={name} />
            </Tooltip>
          </div>
        </div>
      </div>
    )
  }
  return <div></div>
}
