import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import WarningIcon from '@material-ui/icons/Warning'
import {
  DataProviderProxy,
  RedirectionSideEffect,
  ResourceMatch,
  useRedirect,
} from 'ra-core'
import React, {
  ComponentType,
  ReactNode,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Button,
  Create,
  CreateProps,
  Edit,
  EditProps,
  Record,
  SaveButton,
  SimpleForm,
  Toolbar,
  ToolbarProps,
  useDataProvider,
  useNotify,
} from 'react-admin'
import { useFormState } from 'react-final-form'
import { ResourceTraverser } from '../dataProvider/introspections/SchemaTraverser'
import LocalStorage, { EDIT_STORAGE_KEY } from '../datagrid/LocalStorage'
import { useResourceTraverser } from '../hooks/useSchemaTraverser'
import { getErrorNotificationMessages } from '../i18n/errorMessages'
import { PageExitPrompt } from '../lib/PageExitPrompt'
import { filterNotEmpty } from '../lib/filterNonEmpty'
import { TitleComponent } from '../utils/TitleComponent'
import { useConfirmation } from './ConfirmationService'
import {
  PostSavePopupImpl,
  PostSavePopupProps,
  cmsWithPostSavePopup,
} from './PostSavePopup'
import {
  CreateOrEditAction,
  SimpleCreateOrEditAction,
} from './customActions/CreateOrEditAction'
import { CategoryInputForm } from './customForm/CategoryInputForm'
import {
  CustomInputFormProps,
  MovieInputForm,
} from './customForm/MovieInputForm'
import {
  cmsWithCustomCategorySave,
  customCategoryCreateSave,
  customCategoryEditSave,
} from './customSave/customCategorySave'
import {
  cmsWithCustomSave,
  customCreateSave,
  customEditSave,
} from './customSave/customSave'
import {
  cmsWithCustomMovieSave,
  movieCreateSave,
  movieEditSave,
} from './customSave/movieSave'
import { renderInput } from './renderInput'
import {
  ADVANCED,
  CUSTOM_DATA,
  GroupOrder,
  IMAGE,
  NONE,
  OTHERS,
  SMART_CATEGORY,
  SYNOPSIS,
  TypeDisplaySetting,
  createEditTypeNameKeysArray,
  defaultListShowEditAndCreate,
} from './settings/TypeDisplaySetting'

import { Tooltip } from '@material-ui/core'
import { checkFieldForImageGroupInclusion } from './CustomTab'
import { smplTexts } from './CustomTextMappings'

// INFO also extend the extractAndRemoveCustomFields() and removeCustomFields() otherwise saving will fail
/**
 * For normal Edit/Create page, must be extracted in onSave func -> extractAndRemoveCustomFields()
 */
export const SMPL_TEMP_CATEGORIES = 'smpl_categories'
export const SMPL_TEMP_PERSON = 'smpl_person'
export const SMPL_TEMP_IMG_FILES = 'smpl_image_files'
export const SMPL_TEMP_LICENSE = 'smpl_license'
export const SMPL_TEMP_DIRECTOR = 'smpl_director'
export const SMPL_TEMP_PRODUCER = 'smpl_producer'
export const SMPL_TEMP_ACTOR = 'smpl_actor'

/**
 * For BatchEdit, must be extracted in onSave func -> extractAndRemoveCustomFields()
 * The 'for_removal' part is important
 */
export const SMPL_TEMP_CATEGORIES_FOR_REMOVAL = 'smpl_categories_for_removal'
export const SMPL_TEMP_STRINGLIST_FOR_REMOVAL = 'smpl_stringlist_for_removal'

export type StringListForRemoval = {
  [fieldName in string]: string[]
}

export const cmsWithCat = ['CmsMovie', 'CmsSery', 'CmsFeedObject']
export const cmsWithPerson = [
  'CmsMovie',
  'CmsSery',
  'CmsSeason',
  'CmsEpisode',
  'CmsFeedObject',
]

export const cmsWithLicense = ['CmsMovie', 'CmsEpisode']

const useStyles = makeStyles({
  formInputAction: {
    width: '100%',
    display: 'flex',
    zIndex: 2,
    position: 'relative',
    alignItems: 'center',
  },
  formInputContent: {
    display: 'inline-grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    overflow: 'auto',
    // gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridGap: '1rem',
    height: '100%',
    width: 'calc(100% - 30px)', // eye-balled, not sure why 100% is larger
    '& > div': {
      width: 'inherit',
    },
  },
  groupContent: {
    display: 'inline-grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    // overflow: 'auto',
    gridGap: '1rem',
    height: '100%',
    width: '100%', // eye-balled, not sure why 100% is larger
  },
  groupContentFullWidth: {
    display: 'inline-grid',
    // gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    // overflow: 'auto',
    gridGap: '1rem',
    height: '100%',
    width: '100%', // eye-balled, not sure why 100% is larger
  },
  groupWrapper: {
    paddingTop: 10,
    width: 'auto',
    marginBottom: '10px !important',
  },
  dropZoneField: {
    marginTop: '0px !important',
  },
  // TODO: placing groups 2 below groups 1 if display is small (handheld)
  groupsWrapper: {
    width: '100%',
    // padding: '10px',
    paddingTop: '10px !important',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    '& > div': {
      flex: '1',
      padding: '10px',
      minWidth: 350,
      maxWidth: '100%',
      '& > div': {
        width: 'auto',
      },
    },
  },
  panelSummary: {
    '& > div': {
      margin: '0px !important',
    },
  },
  relatedFields: {
    marginTop: '19px',
  },
  divider: {
    marginTop: 25,
    marginBottom: 25,
  },
  invisibleGroup: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  stickyToolbar: {
    position: 'sticky',
    bottom: 0,
    zIndex: 2,
  },
  stickyElementContainerOverflow: {
    '& .MuiPaper-root': {
      overflow: 'visible',
    },
  },
})

export type EditInternalProps = EditProps & {
  id: string
  basePath?: string
  location: any
  match: ResourceMatch
  options: Object
}

export type CustomSaveProps = {
  values: Record | object
  dataProvider: DataProviderProxy
  resource: string
  previousValues: Record | undefined
}

type CreateSaveFunction = (props: CustomSaveProps) => Promise<{
  data: Record
}>

type EditSaveFunction = (props: CustomSaveProps) => Promise<{
  data: Record
}>

export type ExtendedEditInternalProps = EditInternalProps & {
  storage: {
    get(storageKey: string, key: any): any
    set(storageKey: string, key: any, value: any): void
    getVersion(storageKey: string): any
    setVersion(storageKey: string, value: any): void
  }
  onSave: CreateSaveFunction | EditSaveFunction | undefined
  CustomInputForm?: (props: CustomInputFormProps) => JSX.Element | null
  PostSavePopup?: (props: PostSavePopupProps) => JSX.Element | null
}

type DisableSaveButtonRefHandle = {
  setDisableSaveButton: (flag: boolean) => void
}

export const CustomFormToolBar = forwardRef<
  { setDisableSaveButton: DisableSaveButtonRefHandle },
  ToolbarProps & {
    onSave: CreateSaveFunction | EditSaveFunction
    createOrEdit: string
    queryOpenPostSavePopup: (data: {
      data: Record
      dirtyFields: {
        [x: string]: boolean
      }
    }) => void
    directRedirect: boolean
    customFormToolBarRef: React.MutableRefObject<DisableSaveButtonRefHandle>
  }
>((props, ref) => {
  const redirect = useRedirect()
  const notify = useNotify()
  const formState = useFormState()

  const [shouldWarn, setShouldWarn] = useState<boolean>(true)
  const dataProvider = useDataProvider()
  const {
    createOrEdit,
    basePath,
    onSave,
    queryOpenPostSavePopup,
    directRedirect,
    customFormToolBarRef,
    ...other
  } = props

  const dirtyFields = formState.dirtyFields

  // if any of the string[] or json field was touched (and changed back afterwards) the value of this will remain 'false'
  const disableSave = !(Object.keys(dirtyFields).length > 0)

  // let this here to catch save disabled error on deployment
  console.log('dirtyFields', dirtyFields)
  console.log('disableSave', disableSave)

  const [disableSaveButton, setDisableSaveButton] = useState(false)
  // useImperativeHandle(ref, (): any => {
  //   const handle: DisableSaveButtonRefHandle = {
  //     setDisableSaveButton: setDisableSaveButton,
  //   }
  //   return handle
  // })

  const handleDiscardChanges = () => {
    setShouldWarn(false) // no warning when discarding
    setTimeout(() => {
      redirect('show', basePath, formState.initialValues.id)
    }, 50)
  }

  const handleSave = async (
    values: object,
    redirectTo: RedirectionSideEffect
  ) => {
    setShouldWarn(false) // no warning when saving
    try {
      if (createOrEdit === 'Create') {
        // onSave with no need
        const savedObject = await onSave({
          values: values,
          dataProvider: dataProvider,
          resource: props.resource!,
          previousValues: undefined,
        })

        if (savedObject) {
          if (directRedirect) {
            notify('Element created')
            redirect(redirectTo as string, basePath, savedObject.data.id)
          }
        }
      } else {
        // onSave edit need init values
        const savedObject = await onSave({
          values: values,
          dataProvider: dataProvider,
          resource: props.resource!,
          previousValues: formState.initialValues as Record,
        })

        if (savedObject) {
          if (directRedirect || Object.keys(dirtyFields).length === 0) {
            notify('Element updated')
            redirect(redirectTo as string, basePath, savedObject.data.id)
          } else {
            notify('Element updated')
            // Post Save Popup exist -> open it
            queryOpenPostSavePopup({
              ...savedObject,
              dirtyFields: dirtyFields,
            })
          }
        }
      }
    } catch (error) {
      console.error('Error on saving/updating Element', error)
      const errorText = getErrorNotificationMessages(error)
      // @ts-ignore
      notify(errorText ? errorText.message : 'Saving/Updating failed.', 'error')
    }
  }

  return (
    <>
      <PageExitPrompt
        when={formState.dirty && shouldWarn}
        message="Are you sure you want to leave this page? If you leave before saving, your changes will be lost."
      />
      {/* @ts-ignore TODO internal typescript problem? */}
      <Toolbar {...other}>
        <Tooltip title={smplTexts.editPage.saveAndBackToListButton.tooltip}>
          <SaveButton
            label={smplTexts.editPage.saveAndBackToListButton.title}
            startIcon={smplTexts.editPage.saveAndBackToListButton.icon}
            redirect="list"
            onSave={handleSave}
            disabled={disableSave}
          />
        </Tooltip>

        <Tooltip title={smplTexts.editPage.saveButton.tooltip}>
          <SaveButton
            label={smplTexts.editPage.saveButton.title}
            onSave={handleSave}
            disabled={disableSave}
            style={{ marginLeft: 20 }}
          />
        </Tooltip>
        <Tooltip title={smplTexts.editPage.discardChangesButton.tooltip}>
          <Button
            label={smplTexts.editPage.discardChangesButton.title}
            startIcon={smplTexts.editPage.discardChangesButton.icon}
            variant="contained"
            disabled={disableSave}
            style={{
              marginLeft: 20,
              padding: '6px 15px',
              fontSize: 14,
            }}
            onClick={handleDiscardChanges}
          />
        </Tooltip>
      </Toolbar>
    </>
  )
})

export const compareByPostionInCreateEditTypeName = (
  el1: JSX.Element,
  el2: JSX.Element
) => {
  const i1 = createEditTypeNameKeysArray.findIndex((el) => el === el1.key)
  const i2 = createEditTypeNameKeysArray.findIndex((el) => el === el2.key)
  if (i1 > i2) return 1
  else return -1
}

let disableSaveButtonFlag = false
export const getInputs = (props: {
  traverser: ResourceTraverser | null
  resource: string | undefined
  useDefaultStyle?: boolean
  defaultValues?: {
    [x: string]: any
  }
  // for Batch Edit preventing error on overwriting of unique fields (unique are usually non-nullable)
  disableUniqueInput?: boolean
  allowRemovalOption?: boolean
  hideFields?: string[]
}) => {
  const {
    resource,
    traverser,
    useDefaultStyle = true,
    defaultValues = {},
    disableUniqueInput = false,
    allowRemovalOption = false,
    hideFields = [],
  } = props
  const fields = traverser ? traverser.fields : undefined
  const imageFields = fields
    ? fields.filter(
        (f) =>
          f.field.type.kind === 'OBJECT' && f.field.type.name === 'CmsImage'
      )
    : undefined
  const imageFieldNames = imageFields
    ? imageFields.map((iF) => iF.field.name + 'Id')
    : []

  const inputs = useMemo(() => {
    if (!traverser) {
      throw new Error('Resource not found! ' + resource)
    }

    const renderedInput = traverser.fields
      .filter((field) => {
        if (hideFields.includes(field.name)) {
          return false
        }
        return true
      })
      .map((field) =>
        renderInput({
          field,
          useDefaultStyle,
          defaultValues,
          disableUniqueInput,
          allowRemovalOption,
        })
      )
      .filter(filterNotEmpty)

    const type = {
      ...defaultListShowEditAndCreate.editAndCreate.fields,
      ...(TypeDisplaySetting[resource!]?.editAndCreate?.fields
        ? TypeDisplaySetting[resource!]?.editAndCreate.fields
        : {}),
    }

    if (!type) {
      return renderedInput
    }

    let renderedInputInGroupPrimary: {
      [fieldName in string]: JSX.Element[]
    } = {}

    /**
     * INFO: also change in CreateModal if make change here
     */

    GroupOrder.primary.forEach((group) => {
      if (group !== OTHERS) {
        const inputGroup = renderedInput
          .filter((input) => {
            if (type[input.props.field.name]?.group === NONE) return false
            return type[input.props.field.name]?.group === group
          })
          .sort((a, b) => {
            return compareByPostionInCreateEditTypeName(a, b)
          })

        if (inputGroup && inputGroup.length > 0) {
          renderedInputInGroupPrimary[group] = inputGroup
        }
      } else {
        const restInputGroup = renderedInput.filter(
          (input) =>
            type[input.props.field.name] === undefined ||
            type[input.props.field.name].group === OTHERS
        )
        if (restInputGroup && restInputGroup.length > 0) {
          renderedInputInGroupPrimary[group] = restInputGroup
        }
      }
    })

    let renderedInputInGroupSecondary: {
      [fieldName in string]: JSX.Element[]
    } = {}
    GroupOrder.secondary.forEach((group) => {
      if (group !== OTHERS) {
        const inputGroup = renderedInput
          .filter((input) => {
            if (!fields) return type[input.props.field.name]?.group === group
            else {
              if (
                checkFieldForImageGroupInclusion(
                  input.props.field.name,
                  type,
                  imageFieldNames
                )
              ) {
                if (
                  (group === IMAGE && !type[input.props.field.name]) || // not defined yet in TDS -> new field
                  (group === IMAGE &&
                    /**  defined in {@link TypeDisplaySetting} and is not "Not Displaying" */
                    type[input.props.field.name] !== undefined &&
                    'group' in type[input.props.field.name] &&
                    type[input.props.field.name].group !== NONE)
                ) {
                  return true
                } else {
                  return false
                }
              } else {
                return type[input.props.field.name]?.group === group
              }
            }
          })
          .sort((a, b) => {
            return compareByPostionInCreateEditTypeName(a, b)
          })

        if (inputGroup && inputGroup.length > 0) {
          renderedInputInGroupSecondary[group] = inputGroup
        }
      } else {
        const restInputGroup = renderedInput
          .filter((input) => {
            if (!fields) {
              return (
                type[input.props.field.name] === undefined ||
                type[input.props.field.name].group === OTHERS ||
                type[input.props.field.name].group === undefined
              )
            } else {
              if (
                imageFieldNames.find((iFN) => input.props.field.name === iFN) // is imageField
              ) {
                return false
              } else {
                return (
                  type[input.props.field.name] === undefined ||
                  type[input.props.field.name].group === OTHERS ||
                  type[input.props.field.name].group === undefined
                )
              }
            }
          })
          .sort((a, b) => {
            return compareByPostionInCreateEditTypeName(a, b)
          })
        if (restInputGroup && restInputGroup.length > 0) {
          renderedInputInGroupSecondary[group] = restInputGroup
        }
      }
    })

    // preventing empty spaces on one side
    if (
      Object.keys(renderedInputInGroupPrimary).length === 0 ||
      Object.keys(renderedInputInGroupSecondary).length === 0
    ) {
      const temp = {
        ...renderedInputInGroupPrimary,
        ...renderedInputInGroupSecondary,
      }
      renderedInputInGroupPrimary = {}
      renderedInputInGroupSecondary = {}
      Object.keys(temp).forEach((orderGroup, index) => {
        if (index < Object.keys(temp).length / 2) {
          renderedInputInGroupPrimary[orderGroup] = temp[orderGroup]
        } else {
          renderedInputInGroupSecondary[orderGroup] = temp[orderGroup]
        }
      })
    }

    return {
      primary: renderedInputInGroupPrimary,
      secondary: renderedInputInGroupSecondary,
    }
    // return renderedInput
  }, [traverser, resource])

  return inputs
}

function GenericCreateEditPage(
  CreateOrEdit: ComponentType<
    (EditProps | CreateProps) & { children?: ReactNode }
  >,
  props: ExtendedEditInternalProps
) {
  const ref = useRef<any>()

  const setDisableSaveButtonFlag = (shouldDisable: boolean) => {
    ref?.current?.setDisableSaveButton(shouldDisable)
  }

  const createOrEdit: 'Create' | 'Edit' | string = CreateOrEdit.name
  const redirect = useRedirect()
  const classes = useStyles()
  const { storage, onSave, CustomInputForm, PostSavePopup, ...other } = props
  const internalProps: EditInternalProps = other
  const confirm = useConfirmation()
  const { resource, id, basePath } = internalProps
  const traverser = useResourceTraverser(resource!)

  const [openPostSavePopup, setOpenPostSavePopup] = useState(false)
  const [currentData, setCurrentData] = useState<{
    data: Record
    dirtyFields: {
      [key: string]: boolean
    }
  }>()

  const inputs = getInputs({
    traverser,
    resource,
    useDefaultStyle: !CustomInputForm,
  })

  // get current groups expansion from localStorage
  const getGroupsExpansion = () => {
    return storage.get(EDIT_STORAGE_KEY, resource)
  }

  // save to localStorage whether that group was opened or closed for the next time opening a edit/create page of this resource
  const saveCurrentGroupExpansion = (group: string, expanded: boolean) => {
    const groupsExpansion = getGroupsExpansion()
    const newGroupsExpansion = {
      ...groupsExpansion,
      [group]: expanded,
    }
    storage.set(EDIT_STORAGE_KEY, resource, newGroupsExpansion)
  }

  const onConfirm = (data: {
    data: Record
    dirtyFields: {
      [x: string]: boolean
    }
  }) => {
    // open pop up
    setOpenPostSavePopup(true)
    setCurrentData(data)
  }

  const onDeny = (data: {
    data: Record
    dirtyFields: {
      [x: string]: boolean
    }
  }) => {
    // redirect
    redirect('show', basePath, data.data.id)
  }

  const queryOpenPostSavePopup = (data: {
    data: Record
    dirtyFields: {
      [x: string]: boolean
    }
  }) => {
    confirm({
      title: 'Edit related entries?',
      description: 'Do you want to edit entries related to this object?',
      onConfirm: onConfirm,
      onDeny: onDeny,
      data: data,
    })
  }

  const [isSmartCategoryEnabled, setSmartCategoryEnabled] = useState(false)
  const isInitialCustomFormRenderRef = useRef(true)

  useEffect(() => {
    // when user close the Pop Up, means he is done with it so we can now redirect
    if (currentData && !openPostSavePopup) {
      redirect('show', basePath, currentData.data.id)
    }
  }, [currentData, openPostSavePopup])
  return (
    <>
      <CreateOrEdit
        {...other}
        title={<TitleComponent record={{}} resource={resource} />}
        actions={false} // no action bar, we will handle that inside of form
        className={classes.stickyElementContainerOverflow}
      >
        {/* Inputs are usually not in Array form, but if its then we are in some custom case */}
        {Array.isArray(inputs) ? (
          <SimpleForm redirect="show" submitOnEnter={false}>
            <div className={classes.formInputAction}>
              <SimpleCreateOrEditAction
                CreateOrEdit={CreateOrEdit}
                extendedProps={props}
              />
            </div>
            <div className={classes.formInputContent}>{inputs}</div>
          </SimpleForm>
        ) : (
          <SimpleForm
            toolbar={
              onSave ? (
                <CustomFormToolBar
                  basePath={basePath}
                  onSave={onSave}
                  createOrEdit={createOrEdit}
                  queryOpenPostSavePopup={queryOpenPostSavePopup}
                  directRedirect={!PostSavePopup}
                  customFormToolBarRef={ref}
                  className={classes.stickyToolbar}
                />
              ) : undefined
            }
            submitOnEnter={false}
            redirect="show"
          >
            <div className={classes.formInputAction}>
              <CreateOrEditAction
                CreateOrEdit={CreateOrEdit}
                isSmartCategoryEnabled={isSmartCategoryEnabled}
                setSmartCategoryEnabled={setSmartCategoryEnabled}
                extendedProps={props}
              />
            </div>
            <div className={classes.groupsWrapper}>
              <div>
                {CustomInputForm
                  ? CustomInputForm({
                      inputGroup: inputs.primary,
                      resource: resource!,
                      record: { id: id },
                      classes: classes,
                      setDisableSaveButtonFlag: setDisableSaveButtonFlag,
                      saveCurrentGroupExpansion: saveCurrentGroupExpansion,
                      getGroupsExpansion: getGroupsExpansion,
                      isSmartCategoryEnabled,
                      setSmartCategoryEnabled,
                      isInitialCustomFormRenderRef,
                    })
                  : Object.keys(inputs.primary).map((group) => {
                      const groupsExpansion = getGroupsExpansion()
                      if (
                        groupsExpansion === undefined ||
                        groupsExpansion[group] === undefined
                      ) {
                        saveCurrentGroupExpansion(group, true)
                      }
                      return AccordionGroupRenderer(
                        inputs.primary[group],
                        group,
                        classes,
                        groupsExpansion,
                        saveCurrentGroupExpansion
                      )
                    })}
              </div>
              <div>
                {Object.keys(inputs.secondary).map((group) => {
                  const groupsExpansion = getGroupsExpansion()
                  if (
                    groupsExpansion === undefined ||
                    groupsExpansion[group] === undefined
                  ) {
                    // INFO default to false -> not opening on very first call, except for IMAGE
                    saveCurrentGroupExpansion(
                      group,
                      group === IMAGE ? true : false
                    )
                  }
                  return AccordionGroupRenderer(
                    inputs.secondary[group],
                    group,
                    classes,
                    groupsExpansion,
                    saveCurrentGroupExpansion
                  )
                })}
              </div>
            </div>
          </SimpleForm>
        )}
      </CreateOrEdit>
      {PostSavePopup && currentData && openPostSavePopup ? (
        <PostSavePopup
          currentData={currentData}
          resource={resource!}
          setOpenPostSavePopup={setOpenPostSavePopup}
        />
      ) : null}
    </>
  )
}

export const AccordionGroupRenderer = (
  inputs: JSX.Element[],
  group: string,
  classes: any,
  groupsExpansion: any,
  saveCurrentGroupExpansion: (group: string, expanded: boolean) => void
) => {
  return (
    <Accordion
      key={group}
      className={classes.groupWrapper}
      defaultExpanded={
        groupsExpansion === undefined || groupsExpansion[group] === undefined
          ? true // default true if first time opening this resource or a new group
          : groupsExpansion[group]
      }
      onChange={(e, expanded) => {
        saveCurrentGroupExpansion(group, expanded)
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.panelSummary}
      >
        <Typography variant="h6" gutterBottom>
          {group}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: '8px 24px', display: 'block' }}>
        {group === ADVANCED ? (
          <div style={{ textAlign: 'center' }}>
            <WarningIcon color="error" />
            <Typography variant="body1" gutterBottom>
              Fields in this section are set automatically by the backend. Don't
              make any changes unless you know exactly what you're doing.
            </Typography>
          </div>
        ) : null}
        <div
          className={
            group === SYNOPSIS ||
            group === CUSTOM_DATA ||
            group === SMART_CATEGORY
              ? classes.groupContentFullWidth
              : classes.groupContent
          }
        >
          {inputs}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

//// CREATE
export const GenericCreatePage = (props: CreateProps) => {
  let onSave: CreateSaveFunction
  let CustomInputForm:
    | ((props: CustomInputFormProps) => JSX.Element)
    | undefined

  if (cmsWithCustomSave.includes(props.resource!)) {
    onSave = customCreateSave
  }

  if (cmsWithCustomMovieSave.includes(props.resource!)) {
    onSave = movieCreateSave
    CustomInputForm = MovieInputForm
  }

  if (cmsWithCustomCategorySave.includes(props.resource!)) {
    onSave = customCategoryCreateSave
    CustomInputForm = CategoryInputForm
  }

  const extendedProps = {
    ...props,
    storage: LocalStorage,
    // @ts-ignore "Variable 'onSave' is used before being assigned"
    onSave: onSave,
    CustomInputForm: CustomInputForm,
  }

  return GenericCreateEditPage(
    // @ts-ignore
    Create,
    extendedProps as ExtendedEditInternalProps
  )
}

//// EDIT
export const GenericEditPage = (props: EditProps) => {
  let onSave: EditSaveFunction
  let CustomInputForm:
    | ((props: CustomInputFormProps) => JSX.Element)
    | undefined
  let PostSavePopup:
    | ((props: PostSavePopupProps) => JSX.Element | null)
    | undefined

  if (cmsWithCustomSave.includes(props.resource!)) {
    onSave = customEditSave
  }
  if (cmsWithCustomMovieSave.includes(props.resource!)) {
    onSave = movieEditSave
    CustomInputForm = MovieInputForm
  }
  if (cmsWithCustomCategorySave.includes(props.resource!)) {
    onSave = customCategoryEditSave
    CustomInputForm = CategoryInputForm
  }

  if (cmsWithPostSavePopup.includes(props.resource!)) {
    PostSavePopup = PostSavePopupImpl
  }

  const propsWithUndoable = {
    ...props,
    undoable: false,
    storage: LocalStorage,
    // @ts-ignore "Variable 'onSave' is used before being assigned"
    onSave: onSave,
    CustomInputForm: CustomInputForm,
    PostSavePopup: PostSavePopup,
  }

  return GenericCreateEditPage(
    // @ts-ignore
    Edit,
    propsWithUndoable as ExtendedEditInternalProps
  )
}
