import {
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import { useState } from 'react'
import { Record, SimpleForm, Toolbar, useNotify, useRefresh } from 'react-admin'
import { useFormState } from 'react-final-form'
import LocalStorage, { EDIT_STORAGE_KEY } from '../../datagrid/LocalStorage'
import { useResourceTraverser } from '../../hooks/useSchemaTraverser'
import { CreateOrEditAction } from '../customActions/CreateOrEditAction'
import {
  cleanFields,
  extractAndRemoveCustomFields,
} from '../customSave/movieSave'
import { AccordionGroupRenderer, getInputs } from '../GenericEditPage'
import { createSimpleAuthenticatedClient } from '../node-actions/user/lib/simpleClient'
import { IMAGE } from '../settings/TypeDisplaySetting'

const useStyles = makeStyles({
  cardContent: {
    '& > div:first-child': {
      //  display enables a flex context for all its direct children
      // wrap let it show the children in multiple rows if there are more children then box width
      display: 'inline-grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
      overflow: 'auto',
      // gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridGap: '1rem',
      height: '100%',
      width: 'calc(100% - 30px)', // eye-balled, not sure why 100% is larger
    },
  },
  paper: {
    backgroundColor: 'white',
    maxWidth: '80vw',
    maxHeight: '80vh',
    left: '10vw',
    top: '10vh',
    overflow: 'auto',
    overflowX: 'hidden',
  },
  stickyToolbar: {
    position: 'sticky',
    bottom: 0,
    zIndex: 2,
  },
  dropZoneField: {
    marginTop: 'auto !important',
  },
  header: {
    textAlign: 'center',
    marginTop: '1em',
  },
  formInputAction: {
    width: '100%',
    display: 'flex',
    zIndex: 2,
    position: 'relative',
    alignItems: 'center',
  },
  groupsWrapper: {
    width: '100%',
    // padding: '10px',
    paddingTop: '10px !important',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    '& > div': {
      flex: '1',
      padding: '10px',
      minWidth: 350,
      maxWidth: '100%',
      '& > div': {
        width: 'auto',
      },
    },
  },
  confirmationForm: {
    '& > div': {
      display: 'flex',
      columnGap: 10,
      '& > div': {
        flexGrow: 1,
        '& > div': {
          width: 'auto',
        },
      },
    },
  },
  confirmationGroup: {
    display: 'inline-grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    overflow: 'auto',
    gridGap: '1rem',
    height: '100%',
    width: '100%',
  },
})

type BatchCreateLicenseModalProps = {
  open: boolean
  onClose: () => void
  resource: string
  selectedDataResource: string
  selectedData: Record[]
}

export const BatchCreateLicenseModal = (
  props: BatchCreateLicenseModalProps
) => {
  const {
    open: batchCreateLicenseModalOpen,
    onClose: closeBatchCreateLicenseModal,
    resource,
    selectedDataResource, // if CmsSeries or CmsSeason, we will create the license connected to CmsEpisode
    selectedData,
  } = props
  const storage = LocalStorage
  const classes = useStyles()
  const refresh = useRefresh()
  const notify = useNotify()
  const traverser = useResourceTraverser(resource)
  if (!traverser) throw new Error('Resource not found! ' + resource)

  const dashboardBackendClient = createSimpleAuthenticatedClient(
    process.env.PUBLIC_URL
  )

  /**
   * STATE
   */
  const [isSubmitting, setSubmitting] = useState(false)
  /**
   * STATE END
   */

  const inputs = getInputs({
    traverser,
    resource: 'CmsLicense',
    disableUniqueInput: true,
    allowRemovalOption: true,
    hideFields: [
      'externalIdentifier',
      'contentExternalIdentifier',
      'contentMovieId',
      'contentSeriesId',
      'contentEpisodeId',
      'contentFeedObjectId',
    ],
  })

  // get current groups expansion from localStorage
  const getGroupsExpansion = () => {
    return storage.get(EDIT_STORAGE_KEY, resource)
  }

  // save to localStorage whether that group was opened or closed for the next time opening a edit/create page of this resource
  const saveCurrentGroupExpansion = (group: string, expanded: boolean) => {
    const groupsExpansion = getGroupsExpansion()
    const newGroupsExpansion = {
      ...groupsExpansion,
      [group]: expanded,
    }
    storage.set(EDIT_STORAGE_KEY, resource, newGroupsExpansion)
  }

  const onSave = async (values: Record) => {
    console.log(
      'Creating license with values',
      values,
      'for',
      selectedDataResource
    )
    setSubmitting(true)

    const { valuesWithoutCustomFields, customValues } =
      extractAndRemoveCustomFields(values as Record)
    let cleanValues = cleanFields(valuesWithoutCustomFields)

    try {
      // CREATE LICENCE ENTRIES
      const body = {
        selectedDataResource,
        selectedData: selectedData.map((sD) => {
          return { id: sD.id, contentExternalId: sD.externalIdentifier }
        }),
        values: cleanFields(cleanValues),
      }

      const res = await dashboardBackendClient(
        'POST',
        '/api/bulkResource/create/license',
        body
      )

      if (res.error) {
        console.error(res.error)
        notify(
          `Something went wrong during creation of ${resource} entry/entries. If this problem persists, please contact the support.`,
          'error'
        )
      } else {
        notify(`${resource} entry/entries created successfully.`, 'success')
      }
    } catch (error) {
      console.error(error)
      notify(
        `Failed to connect to server. If this problem persists, please contact the support.`,
        'error'
      )
    } finally {
      setSubmitting(false)
      closeBatchCreateLicenseModal()
      refresh()
    }
  }

  const StickyToolbar = (props: { saveOrConfirm: 'save' | 'confirm' }) => {
    const { saveOrConfirm } = props
    const formState = useFormState()

    return (
      <Toolbar className={classes.stickyToolbar}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSave(formState.values as Record)}
          disabled={isSubmitting}
          startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
        >
          {!isSubmitting
            ? `Create License for ${selectedData.length} ${
                selectedData.length === 1 ? 'entry' : 'entries'
              }`
            : `Creating License `}
        </Button>
      </Toolbar>
    )
  }

  return (
    <>
      {/* BATCH CREATE LICENSE MODAL */}
      <Modal
        open={batchCreateLicenseModalOpen}
        onClose={closeBatchCreateLicenseModal}
        style={{
          top: 10 + '%',
          left: 10 + '%',
          width: '80vw',
        }}
      >
        <div className={classes.paper}>
          <Typography variant="h5" gutterBottom className={classes.header}>
            Batch Create License for {selectedData.length}{' '}
            {selectedDataResource === 'CmsSery'
              ? 'CmsSeries'
              : selectedDataResource}{' '}
            {selectedData.length > 1 ? 'entries' : 'entry'}
          </Typography>
          {Array.isArray(inputs) ? (
            <SimpleForm
              children={inputs}
              className={classes.cardContent}
              redirect={null} // prevent redirection after create
              submitOnEnter={false}
              toolbar={<StickyToolbar saveOrConfirm="confirm" />}
            />
          ) : (
            <SimpleForm
              submitOnEnter={false}
              redirect={null} // prevent redirection after create
              toolbar={<StickyToolbar saveOrConfirm="confirm" />}
            >
              <div className={classes.formInputAction}>
                <CreateOrEditAction
                  // @ts-ignore
                  CreateOrEdit={{ name: 'Create' }}
                  // @ts-ignore
                  extendedProps={{
                    resource: 'CmsLicense',
                  }}
                />
              </div>
              <div className={classes.groupsWrapper}>
                <div>
                  {Object.keys(inputs.primary).map((group) => {
                    const groupsExpansion = getGroupsExpansion()
                    if (
                      groupsExpansion === undefined ||
                      groupsExpansion[group] === undefined
                    ) {
                      saveCurrentGroupExpansion(group, true)
                    }
                    return AccordionGroupRenderer(
                      inputs.primary[group],
                      group,
                      classes,
                      groupsExpansion,
                      saveCurrentGroupExpansion
                    )
                  })}
                </div>
                <div>
                  {Object.keys(inputs.secondary).map((group) => {
                    const groupsExpansion = getGroupsExpansion()
                    if (
                      groupsExpansion === undefined ||
                      groupsExpansion[group] === undefined
                    ) {
                      // INFO default to false -> not opening on very first call, except for IMAGE
                      saveCurrentGroupExpansion(
                        group,
                        group === IMAGE ? true : false
                      )
                    }
                    return AccordionGroupRenderer(
                      inputs.secondary[group],
                      group,
                      classes,
                      groupsExpansion,
                      saveCurrentGroupExpansion
                    )
                  })}
                </div>
              </div>
            </SimpleForm>
          )}
        </div>
      </Modal>
    </>
  )
}
