import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fade from '@material-ui/core/Fade'
import Tooltip from '@material-ui/core/Tooltip'
import { green, red } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import Eyecon from '@material-ui/icons/RemoveRedEye'
import { useRefresh } from 'ra-core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useMutation, useNotify } from 'react-admin'
import { useDropzone } from 'react-dropzone'
import { v4 as uuid } from 'uuid'
import { authedFetch } from '../../dataProvider/authedFetch'
import { IMG_CLOUDFRONT_URL_PREFIX } from '../../lib/config'

const useStyles = makeStyles(() => ({
  dropZoneField: {
    padding: '16px',
    border: '1px #e8e8e8 solid',
    borderRadius: '3px',
    display: 'inline-block',
    backgroundColor: 'white',
    marginTop: '-20px',
    width: '-webkit-fill-available',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    border: '2px #eeeeee dashed',
    backgroundColor: '#fafafa',
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
  },
  thumbAndButtonContainer: {
    // 16:9
    height: 90,
    width: 160,
    marginBottom: 8,
    marginRight: 8,
  },
  thumb: {
    position: 'relative',
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    // 16:9
    height: 90,
    width: 160,
    padding: 4,
    boxSizing: 'border-box',
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%',
  },
  imgIconTopCorner: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    height: '25px',
    width: '25px',
    borderLeft: '1px solid lightgrey',
    borderBottom: '1px solid lightgrey',
    backgroundColor: 'white',
    borderRadius: '0px 0px 0px 6px',
    zIndex: 1000,
  },
  imgIconBottomCorner: {
    position: 'absolute',
    bottom: '0px',
    right: '0px',
    height: '25px',
    width: '25px',
    borderLeft: '1px solid lightgrey',
    borderTop: '1px solid lightgrey',
    backgroundColor: 'white',
    borderRadius: '6px 0px 0px 0px',
    zIndex: 1000,
  },
  uploadButton: {
    float: 'right',
    marginTop: '10px',
    marginRight: '1.5vw',
  },
}))

type ThumbNailProps = {
  customFile: CustomFile
  removeFromFiles: Function
}

const ThumbNail = (props: ThumbNailProps) => {
  const classes = useStyles()
  const { customFile, removeFromFiles } = props
  const { state, file, id, errorMessage } = customFile
  const { name } = file

  const handleClickOpen = (url: string) => {
    var win = window.open(url, '_blank')
    if (win) {
      win.focus()
    }
  }

  if (customFile) {
    return (
      <div className={classes.thumbAndButtonContainer}>
        <div className={classes.thumb} key={name}>
          <div className={classes.thumbInner}>
            <img src={customFile.preview} className={classes.img} alt={name} />
          </div>
          <div className={classes.imgIconTopCorner}>
            {state === 'waiting' && (
              <Tooltip
                title="Delete"
                placement="top"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 500 }}
                enterDelay={100}
              >
                <ClearIcon
                  style={{ color: red[500], cursor: 'pointer' }}
                  onClick={() => removeFromFiles(customFile)}
                />
              </Tooltip>
            )}
            {state === 'uploading' && (
              <CircularProgress color="primary" size={23} />
            )}
            {state === 'uploaded' && (
              <Tooltip
                title="Upload Success"
                placement="top"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 500 }}
                enterDelay={100}
              >
                <CheckIcon style={{ color: green[500] }} />
              </Tooltip>
            )}
            {state === 'error' && (
              <Tooltip
                title={errorMessage ? errorMessage : 'Upload failed'}
                placement="top"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 500 }}
                enterDelay={100}
              >
                <ErrorOutlineIcon style={{ color: red[500] }} />
              </Tooltip>
            )}
          </div>
          {state === 'uploaded' && id !== undefined && (
            <div className={classes.imgIconBottomCorner}>
              <Tooltip
                title="Show In New Tab"
                placement="bottom"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 500 }}
                enterDelay={100}
              >
                <Eyecon
                  style={{ color: green[500], cursor: 'pointer' }}
                  onClick={() => {
                    handleClickOpen(
                      process.env.PUBLIC_URL + '/CmsImage/' + id + '/show'
                    )
                  }}
                />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    )
  }
  return <div></div>
}

export type CustomFile = {
  preview: string
  file: File
  state: 'waiting' | 'uploading' | 'uploaded' | 'error'
  masterUrl?: string
  id?: string
  errorMessage?: string
}

type DropZoneProps = {
  returnIdToModal?: Function | null
  callFromEdit?: Boolean
  styles?: any
  returnInfoToModal?: React.Dispatch<
    React.SetStateAction<
      | {
          name: string
          masterUrl: string
          width: number
          height: number
        }
      | undefined
    >
  >
}

export function DropZone(props: DropZoneProps) {
  const {
    returnIdToModal,
    styles,
    callFromEdit = false,
    returnInfoToModal,
  } = props
  const resource = 'CmsImage'
  const classes = useStyles()
  const [files, setFiles] = useState<Array<CustomFile>>([])
  const [lastUpdatedId, setLastUpdatedId] = useState<string>('')
  const [mutate, { data }] = useMutation()
  const notify = useNotify()
  const refresh = useRefresh()

  const onDrop = useCallback(
    (acceptedFiles) => {
      const newFiles: CustomFile[] = acceptedFiles
        .map((file: File) => {
          const originalFileName = file.name
          const replacedFileName = replaceSpecialCharacters(originalFileName)

          if (!isValidImageFileName(replacedFileName)) {
            notify(
              `Invalid file name "${originalFileName}". Allowed characters: a-z, A-Z, 0-9, -, _, and a single . for extension.`,
              'warning'
            )
            return null
          }

          const fileWithReplacedName = new File([file], replacedFileName, {
            type: file.type,
          })
          const preview = URL.createObjectURL(fileWithReplacedName)
          return {
            preview: preview,
            file: fileWithReplacedName,
            state: 'waiting',
          }
        })
        .filter(Boolean) as CustomFile[]

      setFiles([...files, ...newFiles])
    },
    [files, notify]
  )

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/jpg', 'image/jpeg', 'image/png'], // more img types?
    onDrop,
    multiple:
      returnIdToModal === null && returnInfoToModal === undefined
        ? true
        : false, // prevent adding of multiple files in modal
    maxSize: 5.7 * 1024 ** 2, // 5.7MiB
    onDropRejected: (rejectedFiles) => {
      notify(
        'Some files were rejected. Please make sure the file is an image and does not exceed 5.7MiB.',
        'error'
      )
    },
  })

  // clean up preview images when component unmounts
  const filesRef = useRef(files)
  filesRef.current = files // get the latest files
  useEffect(() => {
    return () => {
      if (filesRef.current.length > 0) {
        filesRef.current.forEach((file) => URL.revokeObjectURL(file.preview))
      }
    }
  }, [])

  const removeFromFiles = useCallback(
    (file: CustomFile) => {
      const index = files.indexOf(file)
      URL.revokeObjectURL(file.preview)
      if (index > -1) {
        let newFiles = [...files]
        newFiles.splice(index, 1) // remove at that position and re-init index
        setFiles(newFiles)
      }
    },
    [files]
  )

  const setFileState = (
    fileToChange: CustomFile,
    state: 'waiting' | 'uploading' | 'uploaded' | 'error',
    errorMessage?: string
  ) => {
    // callback setState to only change 1 thing or many, useful inside async call
    setFiles((files) => {
      return files.map((file) => {
        if (file.file !== fileToChange.file) {
          return file
        }
        return {
          ...file,
          state: state,
          errorMessage: errorMessage,
        }
      })
    })
  }

  // set (unique) masterUrl in CustomFile for the allocation of the returned id's after create
  const setFileUrl = (fileToChange: CustomFile, masterUrl: string) => {
    setFiles((files) => {
      return files.map((file) => {
        if (file.file !== fileToChange.file) {
          return file
        }
        return {
          ...file,
          masterUrl: masterUrl,
        }
      })
    })
  }

  // only allow 1 file inside upload modal
  useEffect(() => {
    if ((returnIdToModal || returnInfoToModal) && files.length > 1) {
      removeFromFiles(files[0])
    }
  }, [returnIdToModal, returnInfoToModal, files, removeFromFiles])

  // running after create finish, set id in CustomFile for 'Open in new Tab'
  // or just return the Id of the crated CmsImage if uploaded inside a modal
  useEffect(() => {
    if (data && lastUpdatedId !== data.id) {
      // called from modal
      if (returnIdToModal) {
        returnIdToModal(data.id)
      }
      const fileToChange = files.find(
        (file) =>
          file.masterUrl !== undefined && file.masterUrl === data.masterUrl
      )
      if (fileToChange !== undefined) {
        setFiles((files) => {
          return files.map((file) => {
            if (file.file !== fileToChange.file) {
              return file
            }
            return {
              ...file,
              id: data.id,
            }
          })
        })
        setLastUpdatedId(data.id)
      }
    }
  }, [data, files, lastUpdatedId, returnIdToModal])

  const handleUpload = async () => {
    const promises = files.map(async (file) => {
      if (file.state === 'waiting') {
        try {
          return await uploading(file)
        } catch (error) {
          setFileState(file, 'error')
          return false
        }
      }
      return true
    })

    const resolveArr = await Promise.all(promises)
    const withoutError = resolveArr.find((r) => r === false)

    if (withoutError !== undefined && withoutError === false) {
      if (!returnIdToModal && !returnInfoToModal) {
        notify('There were errors uploading some images.', 'warning')
      } else {
        notify('There was an error uploading the image.', 'warning')
      }
    } else {
      if (!returnIdToModal && !returnInfoToModal) {
        notify('All uploads successful.')
      } else {
        notify('Image upload successful.')
      }
    }
    // prevent refresh after upload is complete or error inside a modal or in edit
    if (!returnIdToModal && !callFromEdit) {
      refresh()
    }
  }

  const uploading = async (customFile: CustomFile) => {
    const { file, preview } = customFile

    // get dimension(height, width) for CmsImage object
    const { width, height } = await new Promise<{
      width: number
      height: number
    }>(function (resolve, reject) {
      const img = new Image()
      let width, height
      img.onload = () => {
        width = img.width
        height = img.height
        if (width && height) {
          resolve({ width, height })
        } else {
          reject(new Error('No Dimension'))
        }
      }
      img.onerror = () => {
        reject(new Error('Not an image.'))
      }
      img.src = preview
    })

    const bodyData = {
      name: file.name,
    }
    const params: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyData),
    }

    try {
      // get presigned aws url for upload
      const res = await authedFetch(
        process.env.PUBLIC_URL + '/api/getPreSignedS3Url',
        params
      )
      const resData = await res.json()

      if (resData.success === true) {
        const { url, fields } = resData.preSignedUrl

        // create form for upload
        const formData = new FormData()
        formData.append('Content-Type', file.type)
        Object.entries(fields).forEach(([k, v]) => {
          formData.append(k, v as string)
        })
        formData.append('file', file)

        try {
          // set state from waiting to uploading
          setFileState(customFile, 'uploading')

          // uploading
          const uploadRes = await fetch(url, {
            method: 'POST',
            body: formData,
          })

          if (uploadRes.ok && width && height) {
            // create new CmsImage with url + fields.key from fetch above
            const cloudfrontUrl = IMG_CLOUDFRONT_URL_PREFIX
            const masterUrl = cloudfrontUrl + '/' + fields.key
            try {
              if (returnInfoToModal === undefined) {
                await mutate({
                  type: 'create',
                  resource: resource,
                  payload: {
                    data: {
                      id: '000000000000000',
                      name: file.name.replace(/\.[^/.]+$/, ''),
                      masterUrl: masterUrl,
                      width: width,
                      height: height,
                    },
                  },
                })
              } else {
                returnInfoToModal({
                  name: file.name.replace(/\.[^/.]+$/, ''),
                  masterUrl: masterUrl,
                  height: height,
                  width: width,
                })
              }
              // set state von uploading auf uploaded
              setFileState(customFile, 'uploaded')
              setFileUrl(customFile, masterUrl)
            } catch (error) {
              // set state von uploading auf error
              setFileState(
                customFile,
                'error',
                'The upload was successful, but an error occurred while creating a new CmsImage.'
              )
              console.error(error)
              return false
            }
          } else {
            // upload to aws failed ok === false
            // set state von uploading auf error
            setFileState(
              customFile,
              'error',
              'Upload failed for image "' +
                file.name +
                '". Maybe the file is too big (max 20MB) or your internet connectivity is unstable. If this problem persists, please contact the support.'
            )
            return false
          }
        } catch (error) {
          // fetching aws s3 url failed
          // set state von uploading auf error
          setFileState(
            customFile,
            'error',
            'Upload failed for image "' +
              file.name +
              '". Maybe the file is too big (max 20MB) or your internet connectivity is unstable. If this problem persists, please contact the support.'
          )
          console.error(error)
          return false
        }
      } else {
        return false
      }
    } catch (error) {
      setFileState(
        customFile,
        'error',
        'Internal server error. Can not get presigned upload url. If this problem persists, please contact the support.'
      )
      console.error(error)
      return false
    }

    return true
  }

  return (
    <div
      className={`${classes.dropZoneField} ${
        styles?.dropZoneField ? styles.dropZoneField : null
      }`}
    >
      <section className={classes.container}>
        <div {...getRootProps()} className={classes.dropzone}>
          <input {...getInputProps()} />
          {returnIdToModal || returnInfoToModal ? (
            <p>
              Drag 'n' drop a file here, or click to select a file (max. 5.7MiB)
            </p>
          ) : (
            <p>Drag 'n' drop some files here, or click to select files</p>
          )}
        </div>
        {files ? (
          <div className={classes.thumbsContainer}>
            {files.map((file: CustomFile) => {
              const uniqueId: string = uuid()
              return (
                <ThumbNail
                  customFile={file}
                  removeFromFiles={removeFromFiles}
                  key={file.file.name + '-' + uniqueId}
                />
              )
            })}
          </div>
        ) : (
          <div></div>
        )}
      </section>
      {files.length > 0 &&
      files.find((file) => file.state === 'waiting') !== undefined ? (
        <Button
          variant="contained"
          color="primary"
          className={classes.uploadButton}
          onClick={handleUpload}
        >
          Upload
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          className={classes.uploadButton}
          disabled
        >
          Upload
        </Button>
      )}
    </div>
  )
}

/**
 * Validates a file name to ensure it contains only allowed characters.
 * Allowed characters: a-z, A-Z, 0-9, -, _, .
 * The file extension must only contain alphabetic characters. - Currently limited to jpg/jpeg/png anyway.
 *
 * @param fileName - The file name to be validated.
 * @returns A boolean indicating if the file name is valid.
 */
export const isValidImageFileName = (fileName: string): boolean => {
  const allowedFileNameCharsRegex = /^[a-zA-Z0-9\-_]+$/
  const allowedFileExtensionRegex = /^[a-zA-Z]+$/

  const parts = fileName.split('.')
  if (parts.length !== 2) {
    return false
  }

  const [name, extension] = parts
  return (
    allowedFileNameCharsRegex.test(name) &&
    allowedFileExtensionRegex.test(extension)
  )
}

/**
 * Replaces special characters in a file name with specified replacements.
 *
 * @param fileName - The original file name.
 * @returns The file name with special characters replaced.
 */
export const replaceSpecialCharacters = (fileName: string): string => {
  return fileName
    .replace(/ß/g, 'ss')
    .replace(/ä/g, 'ae')
    .replace(/ö/g, 'oe')
    .replace(/ü/g, 'ue')
}
