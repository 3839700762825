import { Button } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import React, { Suspense, useEffect, useRef, useState } from 'react'
import {
  CreateProps,
  Record,
  SimpleForm,
  Toolbar,
  useMutation,
  useNotify,
} from 'react-admin'
import { useFormState } from 'react-final-form'
import LocalStorage, { EDIT_STORAGE_KEY } from '../../datagrid/LocalStorage'
import { useResourceTraverser } from '../../hooks/useSchemaTraverser'
import { filterNotEmpty } from '../../lib/filterNonEmpty'
import { AccordionGroupRenderer, getInputs } from '../GenericEditPage'
import { CreateOrEditAction } from '../customActions/CreateOrEditAction'
import { renderInput } from '../renderInput'
import { IMAGE } from '../settings/TypeDisplaySetting'

const useStyles = makeStyles({
  cardContent: {
    '& > div:first-child': {
      //  display enables a flex context for all its direct children
      // wrap let it show the children in multiple rows if there are more children then box width
      display: 'inline-grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
      overflow: 'auto',
      // gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridGap: '1rem',
      height: '100%',
      width: 'calc(100% - 30px)', // eye-balled, not sure why 100% is larger
    },
  },
  paper: {
    backgroundColor: 'white',
    maxWidth: '80vw',
    maxHeight: '80vh',
    left: '10vw',
    top: '10vh',
    overflow: 'auto',
    overflowX: 'hidden',
  },
  stickyToolbar: {
    position: 'sticky',
    bottom: 0,
    zIndex: 2,
  },
  dropZoneField: {
    marginTop: 'auto !important',
  },
  header: {
    textAlign: 'center',
    marginTop: '1em',
  },
  formInputAction: {
    width: '100%',
    display: 'flex',
    zIndex: 2,
    position: 'relative',
    alignItems: 'center',
  },
  groupsWrapper: {
    width: '100%',
    // padding: '10px',
    paddingTop: '10px !important',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    '& > div': {
      flex: '1',
      padding: '10px',
      minWidth: 350,
      maxWidth: '100%',
      '& > div': {
        width: 'auto',
      },
    },
  },
})

const ImgDropZone = React.lazy(async () => {
  return import('../customFields/DropZone').then(({ DropZone }) => {
    return {
      default: DropZone,
    }
  })
})

function CreateModalImpl(props: ModalEditInternalProps) {
  const classes = useStyles()
  const {
    open,
    onClose,
    setFunction,
    setFunctionEntry,
    onCloseDataGridModal,
    fieldName,
    record = {},
    callFromCreate,
    createInfoText,
    ...other
  } = props

  const storage = LocalStorage

  let [mutate, { data, error, loading, loaded }] = useMutation()
  const refData = useRef()
  const refError = useRef()
  const refInput = useRef<JSX.Element[]>()
  const [newCall, setNewCall] = useState(false)
  const internalProps: { resource: string } = other
  const { resource } = internalProps
  const notify = useNotify()
  const traverser = useResourceTraverser(resource)
  const [uploadedImageValues, setUploadedImageValues] = useState<
    | {
        name: string
        masterUrl: string
        width: number
        height: number
      }
    | undefined
  >()
  const [replaceForm, setReplaceForm] = useState(false) // for using with image upload

  useEffect(() => {
    if (error && refError.current !== error) {
      refError.current = error
      notify(error.message, 'warning')
    }
    if (newCall) {
      if (data && refData.current !== data) {
        refData.current = data
        if (typeof setFunction !== 'undefined') {
          setFunction(data.id)
        }
        if (typeof setFunctionEntry !== 'undefined') {
          setFunctionEntry(data)
        }
        notify('Resource created.')
        onClose()
        if (typeof onCloseDataGridModal !== 'undefined') {
          onCloseDataGridModal()
        }
      }
    }
  }, [
    data,
    error,
    loading,
    loaded,
    newCall,
    notify,
    onClose,
    setFunction,
    onCloseDataGridModal,
  ])

  if (!traverser) {
    throw new Error('Resource not found! ' + resource)
  }

  const inputs = getInputs({ traverser, resource, defaultValues: record })

  // get current groups expansion from localStorage
  const getGroupsExpansion = () => {
    return storage.get(EDIT_STORAGE_KEY, resource)
  }

  // save to localStorage whether that group was opened or closed for the next time opening a edit/create page of this resource
  const saveCurrentGroupExpansion = (group: string, expanded: boolean) => {
    const groupsExpansion = getGroupsExpansion()
    const newGroupsExpansion = {
      ...groupsExpansion,
      [group]: expanded,
    }
    storage.set(EDIT_STORAGE_KEY, resource, newGroupsExpansion)
  }

  useEffect(() => {
    if (uploadedImageValues) {
      refInput.current = traverser.fields
        .map((field) => {
          return renderInput({
            field,
            defaultValues: uploadedImageValues,
            useDefaultStyle: true,
          })
        })
        .filter(filterNotEmpty)
      setReplaceForm(true)
    }
  }, [uploadedImageValues, traverser, setReplaceForm])

  const onSave = (props: { [fieldName in string]: any }) => {
    mutate({
      type: 'create',
      resource: resource,
      payload: {
        data: props,
      },
    })
    setNewCall(true)
  }

  const uploadedImage = (
    values: React.SetStateAction<
      | {
          name: string
          masterUrl: string
          width: number
          height: number
        }
      | undefined
    >
  ) => {
    setReplaceForm(false) // remove all current infos in 1 frame
    setUploadedImageValues(values) // this will trigger useEffect -> render simpleForm again with new infos
  }

  const StickyToolbar = () => {
    const formState = useFormState()

    return (
      <Toolbar className={classes.stickyToolbar}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSave(formState.values)}
        >
          Save
        </Button>
      </Toolbar>
    )
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{ top: 10 + '%', left: 10 + '%', width: '80vw' }}
    >
      <div className={classes.paper}>
        {fieldName ? (
          <Typography variant="h5" gutterBottom className={classes.header}>
            Create a new {resource} element for {fieldName}
          </Typography>
        ) : (
          <Typography variant="h5" gutterBottom className={classes.header}>
            Create a new {resource} element
          </Typography>
        )}
        {callFromCreate && createInfoText ? (
          <Typography
            variant="subtitle1"
            gutterBottom
            className={classes.header}
          >
            {createInfoText}
          </Typography>
        ) : null}
        <Suspense
          fallback={
            <div
              style={{
                height: 190,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              Loading Image Upload Zone...
            </div>
          }
        >
          {resource === 'CmsImage' ? (
            <ImgDropZone
              styles={classes}
              callFromEdit={true}
              returnInfoToModal={uploadedImage}
            />
          ) : null}
        </Suspense>
        {replaceForm === false ? (
          Array.isArray(inputs) ? (
            <SimpleForm
              children={inputs}
              className={classes.cardContent}
              redirect={null} // prevent redirection after create
              submitOnEnter={false}
              saving={loading}
              toolbar={<StickyToolbar />}
            />
          ) : (
            <SimpleForm
              submitOnEnter={false}
              redirect={null} // prevent redirection after create
              toolbar={<StickyToolbar />}
            >
              <div className={classes.formInputAction}>
                <CreateOrEditAction
                  // @ts-ignore
                  CreateOrEdit={{ name: 'Create' }}
                  // @ts-ignore
                  extendedProps={{
                    resource: resource,
                  }}
                />
              </div>
              <div className={classes.groupsWrapper}>
                <div>
                  {Object.keys(inputs.primary).map((group) => {
                    const groupsExpansion = getGroupsExpansion()
                    if (
                      groupsExpansion === undefined ||
                      groupsExpansion[group] === undefined
                    ) {
                      saveCurrentGroupExpansion(group, true)
                    }
                    return AccordionGroupRenderer(
                      inputs.primary[group],
                      group,
                      classes,
                      groupsExpansion,
                      saveCurrentGroupExpansion
                    )
                  })}
                </div>
                <div>
                  {Object.keys(inputs.secondary).map((group) => {
                    const groupsExpansion = getGroupsExpansion()
                    if (
                      groupsExpansion === undefined ||
                      groupsExpansion[group] === undefined
                    ) {
                      // INFO default to false -> not opening on very first call, except for IMAGE
                      saveCurrentGroupExpansion(
                        group,
                        group === IMAGE ? true : false
                      )
                    }
                    return AccordionGroupRenderer(
                      inputs.secondary[group],
                      group,
                      classes,
                      groupsExpansion,
                      saveCurrentGroupExpansion
                    )
                  })}
                </div>
              </div>
            </SimpleForm>
          )
        ) : (
          <SimpleForm
            className={classes.cardContent}
            redirect={null} // prevent redirection after create
            submitOnEnter={false}
            saving={loading}
            toolbar={<StickyToolbar />}
          >
            {refInput.current}
          </SimpleForm>
        )}
      </div>
    </Modal>
  )
}

type ModalEditInternalProps = {
  resource: string
  record?: Record
} & CreateInModal

type CreateInModal = {
  open: boolean
  onClose: () => void
  onCloseDataGridModal: () => void
  setFunction?: Function
  setFunctionEntry?: Function
  fieldName?: string
  callFromCreate?: boolean
  createInfoText?: string
}

export const CreateModal = (props: CreateProps & CreateInModal) => {
  return CreateModalImpl(props as ModalEditInternalProps)
}
